/* eslint-disable max-lines */
/* eslint-disable import/exports-last */
/* eslint-disable import/no-unused-modules */
import type { Organization } from "@bay1/sdk/generated/graphql";
import type { PropsWithChildren, Dispatch, SetStateAction } from "react";
import { createContext, useState, useEffect } from "react";
import useSWR from "swr";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import type { DefaultUser } from "next-auth";
import type { DeepReadonly } from "ts-essentials";
import type { NextRouter } from "next/router";

import type { ActiveOrganizationStorage } from "./localStorageProxy";
import { localStorageProxy } from "./localStorageProxy";
import OrganizationDisplay from "./OrganizationDisplay";
import { useInterval } from "./hooks";

export type OrganizationWithJWT = Organization & { jwt: string };
export interface SessionUser extends DefaultUser {
  readonly hasDashboardAccess?: boolean;
  readonly hasDocsAccess?: boolean;
}
export interface CommonAppState {
  readonly organizations?: OrganizationWithJWT[];
  readonly activeOrganization?: OrganizationWithJWT;
  readonly user?: SessionUser;
  readonly loading: boolean;
  readonly loginError?: { message: string };
  readonly currentOrganizationId?: string | undefined;
  readonly triggerTokenRefresh?: () => void;
  readonly setCurrentOrganizationId?: Dispatch<
    SetStateAction<string | undefined>
  >;
  readonly localStore?: ActiveOrganizationStorage | undefined;
}
const localStore = localStorageProxy();

export const CommonAppContext = createContext<CommonAppState>({
  loading: true,
  triggerTokenRefresh: undefined,
  localStore,
});

// eslint-disable-next-line max-statements
const sessionFetcher = async (
  _key: string,
  shouldRefreshToken: boolean,
  setShouldRefreshToken: Dispatch<SetStateAction<boolean>>,
  setTimeSinceLastRefresh: Dispatch<SetStateAction<number>>,
  setOldSession: Dispatch<
    SetStateAction<
      | {
          user: SessionUser;
        }
      | undefined
    >
  >,
  // eslint-disable-next-line consistent-return, max-params
) => {
  if (shouldRefreshToken) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const session = await fetch(`${DASHBOARD_URL}/api/auth/session`, {
        credentials: "include",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      }).then(async (response) => await response.json());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (session.user === undefined) {
        throw new global.Error("Could not fetch session.");
      }
      setOldSession(session as { user: SessionUser; expires: string });
      setShouldRefreshToken(false);
      setTimeSinceLastRefresh(Date.now());
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return session;
    } catch {
      if (
        window.location.pathname.startsWith("/organization") ||
        window.location.pathname.startsWith("/docs")
      ) {
        // eslint-disable-next-line fp/no-mutation
        window.location.href = `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
          window.location.href,
        )}`;
      }
      throw new global.Error("Could not fetch session.");
    }
  }
};

// eslint-disable-next-line max-statements
const organizationsFetcher = async (
  _key: string,
  oldOrganizations: DeepReadonly<OrganizationWithJWT[]> | undefined,
  setOldOrganizations: Dispatch<
    SetStateAction<OrganizationWithJWT[] | undefined>
  >,
) => {
  const response = await fetch(`${DASHBOARD_URL}/api/jwt`, {
    credentials: "include",
  });
  if (response.ok) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const data = await response.json();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (Reflect.has(data, "message")) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      throw new global.Error(data.message);
    }

    if (oldOrganizations) {
      const castData = data as OrganizationWithJWT[];
      const orgIds = oldOrganizations.map((organization) => organization.id);
      const newOrgIds = castData.map((organization) => organization.id);
      if (JSON.stringify(orgIds) === JSON.stringify(newOrgIds)) {
        return oldOrganizations;
      }
    }
    setOldOrganizations(data as OrganizationWithJWT[]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  }

  // List of locations that if fail to obtain org should redirect to signin
  if (
    window.location.pathname.startsWith("/organization") ||
    window.location.pathname.startsWith("/docs") ||
    window.location.pathname.startsWith("/changelog") ||
    window.location.pathname.startsWith("/support") ||
    window.location.host.startsWith("dashboard") ||
    window.location.port === "4000"
  ) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
      window.location.href,
    )}`;
  }
  return oldOrganizations;
};

// eslint-disable-next-line max-statements
export const CommonAppContextProvider = ({
  children,
  router,
}: DeepReadonly<PropsWithChildren<{ router: NextRouter }>>): JSX.Element => {
  const [timeSinceLastRefresh, setTimeSinceLastRefresh] = useState(Date.now());
  const [shouldRefreshToken, setShouldRefreshToken] = useState<boolean>(true);
  const [oldSession, setOldSession] = useState<
    { user: SessionUser; expires: string } | undefined
  >(undefined);
  const [oldOrganizations, setOldOrganizations] = useState<
    OrganizationWithJWT[] | undefined
  >(undefined);

  const triggerTokenRefresh = () => {
    const now = Date.now();
    const diff = (now - timeSinceLastRefresh) / 1000;
    if (diff > 60 * 7.5) {
      setShouldRefreshToken(true);
    }
  };

  const { data: temporarySession } = useSWR<{
    user: SessionUser;
    expires: string;
  }>(
    [
      "session",
      shouldRefreshToken,
      setShouldRefreshToken,
      setTimeSinceLastRefresh,
      setOldSession,
    ],
    sessionFetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: true,
    },
  );
  const session = temporarySession ?? oldSession;
  const isLoading = session === undefined;

  useInterval(() => {
    if (session) {
      const expiresDate = new Date(session.expires).getTime();
      const now = Date.now();
      const diff = (expiresDate - now) / 1000;
      if (diff < 0) {
        setShouldRefreshToken(true);
      }
    }
  }, 10_000);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { data: organizations, error: organizationsError } = useSWR<
    OrganizationWithJWT[]
  >(
    [
      // eslint-disable-next-line unicorn/no-null
      session?.user ? "organizations" : null,
      oldOrganizations,
      setOldOrganizations,
    ],
    organizationsFetcher,
    {
      refreshInterval: 3 * 1000 * 60,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: true,
    },
  );
  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | undefined
  >();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if ("activeOrganizationId" in router.query) {
      setCurrentOrganizationId(router.query.activeOrganizationId as string);
      localStore.setActiveOrganizationId(
        router.query.activeOrganizationId as string,
      );

      if (router.pathname.includes("/organization")) {
        void router.push({
          pathname: router.pathname,
          query: { id: router.query.id },
        });
      } else if (router.pathname.includes("[pageSlug]")) {
        if (
          "anchorTag" in router.query &&
          router.query.anchorTag !== undefined &&
          router.query.anchorTag.length > 0
        ) {
          void router.push({
            pathname: router.pathname,
            hash: router.query.anchorTag as string,

            query: {
              slug: router.query.slug,
              section: router.query.section,
              pageSlug: router.query.pageSlug,
            },
          });
        } else {
          void router.push({
            pathname: router.pathname,

            query: {
              slug: router.query.slug,
              section: router.query.section,
              pageSlug: router.query.pageSlug,
            },
          });
        }
      } else {
        void router.push(router.pathname);
      }
    } else {
      setCurrentOrganizationId(
        organizations?.find(({ id }) => id === (router.query.id as string))
          ? (router.query.id as string)
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            localStore.getActiveOrganizationId()!,
      );
    }
  }, [organizations, currentOrganizationId, router]);

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (organizationsError) {
    return (
      <CommonAppContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values, react-perf/jsx-no-new-object-as-prop
        value={{
          loading: false,
          user: undefined,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          loginError: { message: organizationsError.message },
          localStore,
        }}
      >
        {children}
      </CommonAppContext.Provider>
    );
  }

  if (!organizations) {
    return (
      <CommonAppContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values, react-perf/jsx-no-new-object-as-prop
        value={{ loading: isLoading, user: session?.user, localStore }}
      >
        {children}
      </CommonAppContext.Provider>
    );
  }

  if (organizations.length === 0) {
    return (
      <CommonAppContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values, react-perf/jsx-no-new-object-as-prop
        value={{
          loading: isLoading,
          user: session?.user,
          organizations,
          localStore,
        }}
      >
        <OrganizationDisplay />
      </CommonAppContext.Provider>
    );
  }

  const activeOrganization =
    organizations.find(({ id }) => id === currentOrganizationId) ??
    organizations.find(
      ({ id }) => id === localStore.getActiveOrganizationId(),
    ) ??
    organizations.find(({ id }) => id === router.query.id) ??
    organizations[0];

  return (
    <CommonAppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values, react-perf/jsx-no-new-object-as-prop
      value={{
        loading: isLoading,
        organizations,
        activeOrganization,
        triggerTokenRefresh,
        user: session?.user,
        currentOrganizationId,
        setCurrentOrganizationId,
        localStore,
      }}
    >
      {children}
    </CommonAppContext.Provider>
  );
};

import React from "react";
import type { FormiumClient } from "@formium/client";
import type { FormikProps, FieldProps, FormikHelpers } from "formik";
import { Formik, Form, Field } from "formik";
import { captureException } from "@sentry/browser";
import { ArrowButton } from "@bay1/ui";
import type { DeepReadonly } from "ts-essentials";

// eslint-disable-next-line import/no-relative-packages
import { emailNewsletterValidationSchema } from "../../../dashboard/validation";

interface NewsletterSignupFormProperties {
  formiumClient: FormiumClient;
  onSuccess: () => Promise<void>;
}

interface NewsletterSignupFormValues {
  email: string;
}

const initialValues: NewsletterSignupFormValues = { email: "" };

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
const NewsletterSignupForm = ({
  formiumClient,
  onSuccess,
}: Readonly<NewsletterSignupFormProperties>): JSX.Element => {
  const handleSubmit = React.useCallback(
    async (
      values: Readonly<NewsletterSignupFormValues>,
      actions: Readonly<FormikHelpers<NewsletterSignupFormValues>>,
    ) => {
      try {
        await formiumClient.submitForm("newsletter", values);
        actions.setSubmitting(false);
        await onSuccess();
      } catch (error: unknown) {
        captureException(error);
      }
    },
    [formiumClient, onSuccess],
  );

  return (
    <div className="mt-8 xl:mt-0">
      <h3 className="text-md text-white mb-4">Subscribe to our newsletter</h3>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={emailNewsletterValidationSchema}
      >
        {({
          isSubmitting,
        }: Readonly<
          FormikProps<Readonly<NewsletterSignupFormValues>>
        >): JSX.Element => (
          <Form className="sm:flex-initial inline-flex">
            <label className="sr-only" htmlFor="email">
              Email
            </label>
            <Field id="email" name="email" type="email">
              {({ field, meta }: DeepReadonly<FieldProps>): JSX.Element => (
                <div className="">
                  <label className="sr-only" htmlFor="email">
                    Enter your email
                  </label>
                  <input
                    className="items-center w-40 md:w-full space-x-2 px-4 py-2 border-2 border-white rounded-none bg-transparent rounded-l-full pl-5 text-base placeholder-gray-200 text-white focus:outline-none focus:border-green focus:ring-green"
                    placeholder="Email address"
                    {...field}
                  />
                  {meta.touched && meta.error && (
                    <div className="bg-red text-xs mt-1.5 p-1 table rounded">
                      {meta.error}
                    </div>
                  )}
                </div>
              )}
            </Field>
            <div className="sm:flex-shrink-0">
              <button
                className="relative inline-flex items-center space-x-2 px-4 py-2 border-2 border-transparent rounded-r-full bg-white text-base hover:bg-green hover:text-black hover:border-green focus:outline-none transform duration-200 ease-in-out"
                disabled={isSubmitting}
                type="submit"
              >
                Subscribe <ArrowButton isPrimary />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <p className="mt-3 text-sm text-white">
        You&#39;ll receive occasional emails from Highnote. You always have the
        choice to unsubscribe within every email.
      </p>
    </div>
  );
};

export default NewsletterSignupForm;

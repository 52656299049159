/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import type { PropsWithChildren } from "react";
import { useEffect, useContext } from "react";
import { CommonAppContext } from "@bay1/data";
import Head from "next/head";
import { Footer } from "@bay1/ui";
import { DashboardNav } from "@bay1/ui/components/DashboardNav";
import type { Middleware, SWRHook } from "swr";
import { SWRConfig } from "swr";
import { useRouter } from "next/router";

import { REFRESH_TOKEN } from "../helpers/constants";

import { SidebarCardProducts } from "./SidebarCardProducts";

const swrMiddleware: Middleware =
  (useSWRNext: SWRHook) => (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    const { triggerTokenRefresh } = useContext(CommonAppContext);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (key?.toString().includes(REFRESH_TOKEN) && triggerTokenRefresh) {
      triggerTokenRefresh();
    }
    return swr;
  };

export const LoginGate = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { loginError, triggerTokenRefresh } = useContext(CommonAppContext);

  // Potentially update token on route.
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (triggerTokenRefresh && !url.includes("/auth/")) {
        triggerTokenRefresh();
      }
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, triggerTokenRefresh]);

  if (loginError) {
    return (
      <>
        <Head>
          <title>Highnote</title>
        </Head>
        <div className="flex flex-col h-screen justify-between">
          <DashboardNav>
            <SidebarCardProducts />
          </DashboardNav>
          <div className="flex bg-white">
            <main
              className="flex-1 relative overflow-y-auto focus:outline-none"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
            >
              <div className="max-w-2xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div
                  className="rounded-highnote bg-red-50 p-4"
                  data-testid="errorBox"
                >
                  <h3
                    className="text-sm text-center text-red"
                    data-testid="errorBox::message"
                  >
                    <p className="mb-1 text-lg">Something went wrong.</p>
                    <p>
                      {"Try again or contact "}
                      <a
                        className="underline"
                        href="mailto:support@highnoteplatform.com"
                      >
                        support@highnoteplatform.com
                      </a>
                      .
                    </p>
                  </h3>
                </div>
              </div>
            </main>
          </div>
          <Footer />
        </div>
      </>
    );
  }

  return <SWRConfig value={{ use: [swrMiddleware] }}>{children}</SWRConfig>;
};

import React from "react";
import type { FormiumClient } from "@formium/client";
// eslint-disable-next-line @typescript-eslint/no-shadow
import Image from "next/image";
import Link from "next/link";

import { MARKETING_URL } from "../urlHelper";

import NewsletterSignupForm from "./NewsletterSignupForm";

interface FooterProperties {
  formiumClient?: FormiumClient;
  onNewsletterFormSuccess?: () => Promise<void>;
}

const FOOTER_LINK_CLASS_NAME =
  'group text-base text-white border-b border-transparent hover:border-white transform duration-200 ease-in-out"';

const LEFT_FOOTER_COLUMN_LINKS = [
  ["why-highnote", "Why Highnote"],
  ["platform", "Platform"],
  ["blog", "Resources"],
];

const CENTER_COLUMN_FOOTER_LINKS = [
  ["about", "About"],
  ["press", "Press"],
  ["careers", "Careers"],
  ["contact", "Contact"],
];

const RIGHT_COLUMN_FOOTER_LINKS = [
  ["https://status.highnoteplatform.com", "Platform Status"],
];

const FooterComponent = ({
  formiumClient,
  onNewsletterFormSuccess,
}: Readonly<FooterProperties>): JSX.Element => (
  <footer aria-labelledby="footerHeading" className="bg-black">
    <h2 className="sr-only" id="footerHeading">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers */}
            <div>
              <Image
                alt="Highnote"
                className=""
                height={75}
                src="/img/highnote-badge-white.svg"
                width={75}
              />
            </div>
            <div className="mt-0">
              <ul className="mt-4 space-y-4">
                {LEFT_FOOTER_COLUMN_LINKS.map(([path, linkContent]) => (
                  <li key={path}>
                    <Link href={`${MARKETING_URL}/${path}`}>
                      <a
                        className={FOOTER_LINK_CLASS_NAME}
                        data-testid={`footer::link::${path}`}
                      >
                        {linkContent}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <ul className="mt-4 space-y-4">
              {CENTER_COLUMN_FOOTER_LINKS.map(([path, linkContent]) => (
                <li key={path}>
                  <Link href={`${MARKETING_URL}/${path}`}>
                    <a
                      className={FOOTER_LINK_CLASS_NAME}
                      data-testid={`footer::link::${path}`}
                    >
                      {linkContent}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
            <ul className="mt-4 space-y-4">
              {RIGHT_COLUMN_FOOTER_LINKS.map(([path, linkContent]) => (
                <li key={path}>
                  <Link href={path}>
                    <a
                      className={FOOTER_LINK_CLASS_NAME}
                      data-testid={`footer::link::${path}`}
                    >
                      {linkContent}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-8 xl:mt-0">
          {formiumClient && onNewsletterFormSuccess ? (
            <NewsletterSignupForm
              formiumClient={formiumClient}
              onSuccess={onNewsletterFormSuccess}
            />
          ) : undefined}
        </div>
      </div>
      <div className="mt-8 pt-8 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2">
          <a
            className="text-white hover:text-gray-300 transform duration-200 ease-in-out"
            href="https://www.linkedin.com/company/highnoteplatform"
          >
            <span className="sr-only">LinkedIn</span>
            <svg
              aria-hidden="true"
              className="h-6 w-5"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
          <a
            className="text-white hover:text-gray-300 transform duration-200 ease-in-out"
            href="https://twitter.com/highnotesocial"
          >
            <span className="sr-only">Twitter</span>
            <svg
              aria-hidden="true"
              className="h-6 w-6"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          </a>
        </div>
        <div className="md:mt-0 mt-8 md:order-1">
          <p className="text-base text-white">
            ©2022 Highnote Platform, Inc.
            <a
              className="ml-5 text-base text-white border-b border-transparent hover:border-white transform duration-200 ease-in-out"
              href={`${MARKETING_URL}/agreements/terms`}
            >
              Terms
            </a>
            <a
              className="ml-5 text-base text-white border-b border-transparent hover:border-white transform duration-200 ease-in-out"
              href={`${MARKETING_URL}/agreements/privacy`}
            >
              Privacy
            </a>
          </p>
          <p className="text-gray-400 text-xs">
            Highnote Platform, Inc. is a financial technology company, not a
            bank.
            <br />
            Banking services provided by partner banks, members FDIC.
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default FooterComponent;

import React from "react";
import bay1Client from "@bay1/sdk";
import { CommonAppContext } from "@bay1/data";

export type Bay1GraphQLClient = ReturnType<typeof bay1Client>;

export const useBay1Client = (): { client?: Bay1GraphQLClient } => {
  const { activeOrganization } = React.useContext(CommonAppContext);

  if (!activeOrganization) {
    return {};
  }

  const client = bay1Client(
    activeOrganization.jwt,
    activeOrganization.profile.environment,
  );

  return { client };
};

/* eslint-disable react/function-component-definition */
import { CommonAppContext } from "@bay1/data";
import React, { useContext, useCallback, useEffect } from "react";
import type { SyntheticEvent } from "react";
import Link from "next/link";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";

export default function OrganizationDisplay(): JSX.Element {
  const { organizations, currentOrganizationId, localStore } =
    useContext(CommonAppContext);

  useEffect(() => {
    localStore?.setActiveOrganizationId(currentOrganizationId ?? "");
  }, [currentOrganizationId, localStore]);

  const handleChangeOrganizations = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: SyntheticEvent<HTMLButtonElement>) => {
      // eslint-disable-next-line fp/no-mutation
      window.location.href =
        event.currentTarget.id === IntegrationEnvironment.TEST
          ? `${DASHBOARD_URL}/organizations/${event.currentTarget.value}/get-started`
          : `${DASHBOARD_URL}/organizations/${event.currentTarget.value}/home`;
    },
    [],
  );

  return (
    <div className="min-h-full h-screen bg-bone flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8 mx-auto w-full max-w-md">
        <div className="bg-white px-8 py-8 rounded-highnote">
          <svg
            className="mx-auto"
            fill="none"
            height="100"
            viewBox="0 0 142 142"
            width="100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="70.8989"
              cy="70.8986"
              fill="#55F5A3"
              r="49.8988"
              transform="rotate(45 70.8989 70.8986)"
            />
            <path
              d="M75.3766 65.9987C77.8447 68.4668 77.8447 72.4685 75.3766 74.9366C72.9085 77.4048 68.9068 77.4048 66.4387 74.9366C63.9705 72.4685 63.9705 68.4668 66.4387 65.9987C68.9068 63.5306 72.9085 63.5306 75.3766 65.9987Z"
              fill="black"
            />
            <path
              d="M95.1768 76.8238C96.8608 76.8762 98.5213 76.2283 99.8067 74.9429C102.272 72.4771 102.272 68.4742 99.8041 66.0057C97.3383 63.5399 93.3353 63.5399 90.8695 66.0057C89.5841 67.2911 88.8732 69.046 88.986 70.7065C89.07 72.2437 88.7289 73.7861 87.5747 74.9403C86.4205 76.0945 84.8361 76.2991 83.2386 76.349C81.7187 76.2906 80.2799 76.812 79.0756 77.8335C78.9351 77.9516 78.7985 78.0766 78.6664 78.2088C78.6615 78.2136 78.6567 78.2185 78.6519 78.2233C78.648 78.2272 78.6441 78.2311 78.6401 78.2351C78.3061 78.569 78.0174 78.9311 77.7739 79.3136C77.0642 80.4127 76.7018 81.6853 76.7829 82.9096C76.8668 84.4468 76.5258 85.9892 75.3716 87.1434C74.2174 88.2976 72.633 88.5022 71.0355 88.5521C69.3278 88.4865 67.7224 89.1528 66.437 90.4382C63.9712 92.904 63.9712 96.907 66.4423 99.3728C68.9081 101.839 72.9084 101.841 75.3769 99.3728C76.6622 98.0874 77.3259 96.3482 77.2498 94.672C77.2367 93.2319 77.5148 91.5897 78.669 90.4355C79.8232 89.2813 81.2659 88.9928 82.9054 89.0269H82.9736C84.2944 89.068 85.6006 88.6784 86.7228 87.8883C87.024 87.6781 87.3111 87.4385 87.58 87.1697C87.5845 87.1652 87.5889 87.1607 87.5934 87.1562C87.5968 87.1528 87.6002 87.1494 87.6036 87.146C88.2505 86.4991 88.7277 85.7469 89.0353 84.9447C89.3466 84.1459 89.4906 83.2997 89.4529 82.4689C89.4398 81.0288 89.7179 79.3866 90.8721 78.2324C92.0263 77.0782 93.4691 76.7897 95.1086 76.8238L95.1768 76.8238Z"
              fill="black"
            />
            <path
              d="M70.7389 52.386C72.423 52.4384 74.0835 51.7905 75.3688 50.5051C77.8346 48.0393 77.8346 44.0363 75.3662 41.5679C72.9004 39.1021 68.8974 39.1021 66.4316 41.5679C65.1463 42.8533 64.4354 44.6082 64.5482 46.2687C64.6321 47.8059 64.2911 49.3483 63.1369 50.5025C61.9827 51.6567 60.3983 51.8613 58.8007 51.9112C57.2809 51.8528 55.8421 52.3742 54.6378 53.3957C54.4973 53.5137 54.3607 53.6388 54.2285 53.771C54.2237 53.7758 54.2188 53.7807 54.214 53.7855C54.2101 53.7894 54.2062 53.7933 54.2023 53.7972C53.8683 54.1312 53.5796 54.4932 53.336 54.8757C52.6264 55.9748 52.2639 57.2475 52.3451 58.4718C52.429 60.009 52.088 61.5514 50.9338 62.7056C49.7796 63.8598 48.1952 64.0644 46.5976 64.1143C44.8899 64.0487 43.2845 64.715 41.9992 66.0003C39.5334 68.4661 39.5334 72.4691 42.0044 74.9349C44.4702 77.4007 48.4706 77.4034 50.939 74.9349C52.2244 73.6496 52.8881 71.9104 52.812 70.2342C52.7989 68.794 53.0769 67.1519 54.2311 65.9977C55.3853 64.8435 56.8281 64.555 58.4676 64.5891L58.5358 64.5891C59.8565 64.6302 61.1628 64.2406 62.2849 63.4505C62.5862 63.2403 62.8733 63.0007 63.1421 62.7318C63.1466 62.7274 63.1511 62.7229 63.1555 62.7184C63.1589 62.715 63.1623 62.7116 63.1657 62.7082C63.8127 62.0613 64.2899 61.3091 64.5975 60.5069C64.9088 59.7081 65.0528 58.8618 65.0151 58.0311C65.002 56.5909 65.28 54.9488 66.4342 53.7946C67.5884 52.6404 69.0312 52.3519 70.6707 52.386L70.7389 52.386Z"
              fill="black"
            />
          </svg>

          {organizations && organizations.length > 0 ? (
            <>
              <h1 className="text-xl font-display mt-5 mb-2">
                Select Your Highnote Organization
              </h1>
              <div className="space-y-3">
                {organizations.map((organization) => (
                  <Link
                    href={
                      organization.profile.environment ===
                      IntegrationEnvironment.TEST
                        ? `/organizations/${organization.id}/get-started`
                        : `/organizations/${organization.id}/home`
                    }
                    key={organization.id}
                  >
                    <button
                      className="flex cursor-pointer border-2 border-ash 
                      px-5 py-3 rounded-highnote transform ease-in-out duration-300 w-full hover:bg-bone"
                      data-testid="user-menu-mobile::select::organization"
                      id={organization.profile.environment}
                      onClick={handleChangeOrganizations}
                      type="button"
                      value={organization.id}
                    >
                      <div className="group items-center">
                        <h4 className="font-display text-lg">
                          <span className="text-sm rounded tracking-wider uppercase px-1.5 mr-2 bg-black bg-opacity-10">
                            {organization.profile.environment}
                          </span>
                          {organization.profile.displayName}
                        </h4>
                      </div>
                    </button>
                  </Link>
                ))}
              </div>
            </>
          ) : (
            <>
              <h1 className="text-lg font-display mt-5 mb-2">
                Your account does not belong to any Highnote Organizations
              </h1>
              <p>
                Ask a team member to invite you to your company&#39;s Highnote
                Organization. If you need a new Highnote Organization, please
                contact{" "}
                <a
                  className="cursor-pointer underline hover:text-gray-700"
                  href="mailto:support@highnoteplatform.com"
                >
                  support@highnoteplatform.com
                </a>
                .
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import type { PropsWithChildren } from "react";

const BASE_CLASS = "z-20 relative w-full";

export const NavContainer = ({
  children,
  backgroundClass,
  onClick,
}: Readonly<
  PropsWithChildren<{
    backgroundClass?: string;
    onClick?: () => void;
  }>
>): JSX.Element => (
  <nav
    className={`${BASE_CLASS} ${
      backgroundClass ?? "bg-bone max-w-screen-2xl mx-auto lg:fixed"
    }`}
    onClick={onClick}
  >
    {children}
  </nav>
);

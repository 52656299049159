import { withScope, captureEvent, captureException } from "@sentry/node";
import { ClientError } from "graphql-request";
import type {
  CardProductInput,
  CreateCardProductMutation,
  Maybe,
} from "@bay1/sdk/generated/graphql";
import type { DeepReadonly } from "ts-essentials";

import {
  generateClientErrorMessage,
  isAccessDeniedError,
  AUTHENTICATION_INVALID_MESSAGE,
} from "../helpers";

import { useBay1Client } from "./useBay1Client";

const ERROR_MESSAGE = "Unable to create card product.";

type CardProductFromMutation = Maybe<
  Extract<
    CreateCardProductMutation["createCardProduct"],
    { __typename: "CardProduct" }
  >
>;

export const useCreateCardProduct = (): {
  createCardProduct?: (
    cardProduct: DeepReadonly<CardProductInput>,
  ) => Promise<CardProductFromMutation>;
} => {
  const { client } = useBay1Client();

  if (!client) {
    return {};
  }

  const createCardProduct = async (
    cardProduct: DeepReadonly<CardProductInput>,
  ): Promise<CardProductFromMutation> => {
    try {
      const data = await client.CreateCardProduct({ input: { cardProduct } });

      if (data.createCardProduct?.__typename === "CardProduct") {
        return data.createCardProduct;
      }

      // eslint-disable-next-line no-warning-comments
      // TODO: Handle UserError and AccessDenied errors here

      throw new Error(ERROR_MESSAGE);
    } catch (error: unknown) {
      withScope((scope) => {
        scope.setTransactionName("CreateCardProduct");

        if (error instanceof ClientError) {
          if (isAccessDeniedError(error)) {
            throw new Error(AUTHENTICATION_INVALID_MESSAGE);
          } else {
            captureEvent({
              message: generateClientErrorMessage(ERROR_MESSAGE),

              tags: {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                requestId: error.response.extensions.requestId as string,
              },

              extra: {
                errors: error.response.errors,
              },
            });
          }
        } else {
          captureException(error);
        }

        throw new Error(
          `${ERROR_MESSAGE} Please try again. If the problem persists, contact support@highnoteplatform.com.`,
        );
      });
    }

    return undefined;
  };

  return { createCardProduct };
};

/* eslint-disable max-len */
import type { PropsWithChildren, SyntheticEvent } from "react";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { TestOnly } from "@bay1/ui";
import { MARKETING_URL } from "@bay1/ui/urlHelper";
import type { CardProduct, Maybe } from "@bay1/sdk/generated/graphql";

import { useCardProducts } from "../hooks/useCardProducts";

import { ErrorBox } from "./ErrorBox";
import SlideOver from "./SlideOver";
import { CreateCardProduct } from "./create/CardProduct";
import { SidebarCardProduct } from "./SidebarCardProduct";

const slideOverHeader = {
  title: "Create Card Product",
  subtitle: "Card products allow you to issue cards to your customers.",

  link: {
    href: `${MARKETING_URL}/docs/platform/issuing/card-products`,
    text: "Card Product Documentation",
  },
};

const SidebarCardProductsRoot = ({
  children,
  setIsOpen,
  disabled,
  cardProducts,
}: Readonly<
  PropsWithChildren<{
    setIsOpen?: (isOpen: boolean) => void;
    disabled: boolean;
    cardProducts: Maybe<CardProduct[]>;
  }>
>): JSX.Element => {
  const openSlideOver = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (clickEvent: SyntheticEvent<HTMLDivElement>): void => {
      clickEvent.preventDefault();

      setIsOpen?.(true);
    },
    [setIsOpen],
  );

  return (
    <div
      className="space-y-3"
      data-testid={disabled ? "sidebar::cardProduct:disabled" : ""}
    >
      <div className="space-y-1.5" role="group">
        {children}
        {cardProducts && cardProducts.length > 0 ? (
          <TestOnly>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className={classNames(
                "group flex flex-grow-0 items-center px-1 py-1.5 text-md rounded-rounded hover:bg-ash",
                { "cursor-pointer": !disabled, "cursor-not-allowed": disabled },
              )}
              data-testid="button::create-card-product"
              onClick={openSlideOver}
            >
              <div className="rounded-full ml-1 mr-4 bg-transparent h-5 w-5 border-2 border-black">
                <svg
                  className="text-black"
                  fill="none"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect fill="currentColor" height="2" width="8" x="4" y="7" />
                  <rect fill="currentColor" height="8" width="2" x="7" y="4" />
                </svg>
              </div>
              <span
                className={classNames("cursor-pointer truncate", {
                  "cursor-not-allowed": disabled,
                })}
              >
                Add New Product
              </span>
            </div>
          </TestOnly>
        ) : undefined}
      </div>
    </div>
  );
};

export const SidebarCardProducts = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    cardProducts,
    error: cardProductsError,
    isLoading,
  } = useCardProducts();

  const openSlideOver = useCallback(
    (clickEvent: SyntheticEvent<HTMLAnchorElement> | boolean): void => {
      if (typeof clickEvent === "object" && "preventDefault" in clickEvent) {
        clickEvent.preventDefault();
      }

      setIsOpen(true);
    },
    [],
  );

  if (isLoading) {
    return (
      <SidebarCardProductsRoot cardProducts={cardProducts} disabled>
        <div className="animate-pulse space-y-1.5 px-1">
          {/* eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers */}
          <div>
            <div className="inline-block h-6 w-6 mr-2 bg-ash rounded" />
            <div className="inline-block h-6 w-36 bg-ash rounded" />
          </div>
          {/* eslint-disable-next-line @shopify/jsx-prefer-fragment-wrappers */}
          <div>
            <div className="inline-block h-6 w-6 mr-2 bg-ash rounded" />
            <div className="inline-block h-6 w-28 bg-ash rounded" />
          </div>
        </div>
      </SidebarCardProductsRoot>
    );
  }

  if (cardProductsError) {
    return (
      <SidebarCardProductsRoot cardProducts={cardProducts} disabled>
        <div className="mt-6">
          <ErrorBox message={cardProductsError.message} />
        </div>
      </SidebarCardProductsRoot>
    );
  }

  return (
    <>
      <SlideOver header={slideOverHeader} isOpen={isOpen} setIsOpen={setIsOpen}>
        <CreateCardProduct />
      </SlideOver>

      <SidebarCardProductsRoot
        cardProducts={cardProducts}
        disabled={false}
        setIsOpen={openSlideOver}
      >
        {cardProducts?.length ?? 0 ? (
          cardProducts?.map((cardProduct) => (
            <SidebarCardProduct
              cardProduct={cardProduct}
              key={cardProduct.id}
            />
          ))
        ) : (
          <TestOnly>
            <a
              className="group flex flex-grow-0 items-center px-2 py-1.5 text-md rounded-rounded hover:bg-ash"
              href="#"
              onClick={openSlideOver}
            >
              <span
                aria-hidden="true"
                className="flex-shrink-0 h-5 w-5 mr-4 relative flex items-center justify-center"
              >
                <span className="animate-ping absolute h-4 w-4 rounded-full bg-green-200" />
                <span className="relative block w-2 h-2 bg-green rounded-full transform duration-300 ease-in-out" />
              </span>
              Create First Product
            </a>
          </TestOnly>
        )}
      </SidebarCardProductsRoot>
    </>
  );
};

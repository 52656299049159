import React from "react";
import { CommonAppContext } from "@bay1/data";
import { useRouter } from "next/router";

import { DASHBOARD_URL, MARKETING_URL } from "../urlHelper";
import { ArrowButton } from "..";

import { UserMenu, UserMobileMenu } from "./UserMenu";

export const AccountDesktopNav = (): JSX.Element => {
  const { user } = React.useContext(CommonAppContext);

  return (
    <div className="hidden lg:flex items-center justify-end space-x-4">
      {user ? (
        <UserMenu user={user} />
      ) : (
        <>
          <a
            className="text-sm border-b-2 border-transparent hover:border-black transform duration-500 ease-in-out"
            href={`${DASHBOARD_URL}/auth/signin`}
          >
            Sign in
          </a>
          <a
            className="button button-transparent"
            href={`${MARKETING_URL}/request-demo/`}
          >
            Request Demo
            <ArrowButton isPrimary />
          </a>
        </>
      )}
    </div>
  );
};

export const AccountMobileNav = ({
  children,
}: Readonly<React.PropsWithChildren<unknown>>): JSX.Element => {
  const { user } = React.useContext(CommonAppContext);
  const router = useRouter();
  const { pathname } = router;
  const pathsWithoutNavDetails = ["/", "/about", "/careers", "/contact"];

  return (
    <div className="p-5">
      {user ? (
        <>
          {pathname.includes("/docs") ||
          pathsWithoutNavDetails.includes(pathname) ? (
            ""
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{children}</>
          )}
          <UserMobileMenu user={user} />
        </>
      ) : (
        <a
          className="button button-transparent"
          href={`${DASHBOARD_URL}/auth/signin`}
        >
          Sign in{" "}
          <svg
            className="button-arrow"
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1L18 10M18 10L9 19M18 10L-3.93402e-07 10"
              strokeWidth="2"
            />
          </svg>
        </a>
      )}
    </div>
  );
};

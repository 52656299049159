import React from "react";
import classNames from "classnames";

const ArrowButton = ({
  isDropdown,
  isPrimary,
}: Readonly<{
  isPrimary: boolean;
  isDropdown?: boolean;
}>): JSX.Element => {
  if (isPrimary) {
    return (
      <svg
        className={classNames("button-arrow", {
          "transform rotate-90": isDropdown,
        })}
        fill="none"
        height="20"
        viewBox="0 0 43 20"
        width="43"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.2788 1L41.2788 10M41.2788 10L32.2788 19M41.2788 10L23.2788 10"
          strokeWidth="2"
        />
        <line strokeDasharray="2 4" strokeWidth="2" x2="30" y1="10" y2="10" />
      </svg>
    );
  }

  return (
    <svg
      className={classNames("button-arrow-small", {
        "transform rotate-90": isDropdown,
      })}
      fill="none"
      height="17"
      viewBox="0 0 17 17"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55903 1L15.0459 8.5M15.0459 8.5L7.55903 16M15.0459 8.5L0.072168 8.5"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowButton;

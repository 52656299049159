import { AccountHolderVerificationResultCode } from "@bay1/sdk/generated/graphql";

// eslint-disable-next-line etc/no-enum
export enum VerificationResultStatusForUI {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
  WARNING = "WARNING",
  NOT_RAN = "NOT_RAN",
}

export type VerificationResultMap = Record<
  string,
  {
    [code in AccountHolderVerificationResultCode]?: [
      VerificationResultStatusForUI,
      string,
    ];
  }
>;

export const verificationResultMap: VerificationResultMap = {
  Name: {
    [AccountHolderVerificationResultCode.NAME_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Name matched.",
    ],

    [AccountHolderVerificationResultCode.NAME_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "Name did not match.",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_NAME_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "Name matched.",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_NAME_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "Name did not match.",
    ],
  },

  Device: {
    [AccountHolderVerificationResultCode.DEVICE_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The IP Address used when opening the Application was unrecognized.",
    ],

    [AccountHolderVerificationResultCode.FOREIGN_DEVICE]: [
      VerificationResultStatusForUI.FAIL,
      "The IP Address used when opening the Application was not able to be verified.",
    ],
  },

  "Identification Number": {
    [AccountHolderVerificationResultCode.SSN_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The SSN/TID number provided may be incorrect.",
    ],

    [AccountHolderVerificationResultCode.SSN_MISKEY]: [
      VerificationResultStatusForUI.FAIL,
      "The SSN number was entered incorrectly.",
    ],

    [AccountHolderVerificationResultCode.SSN_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "The SSN number matched.",
    ],

    [AccountHolderVerificationResultCode.SSN_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The SSN number was unable to be verified.",
    ],
  },

  "Date of Birth": {
    [AccountHolderVerificationResultCode.DOB_MISKEY]: [
      VerificationResultStatusForUI.FAIL,
      "The date of birth was entered incorrectly.",
    ],

    [AccountHolderVerificationResultCode.DOB_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "The date of birth provided was matched.",
    ],

    [AccountHolderVerificationResultCode.DOB_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The date of birth provided did not match.",
    ],
  },

  Email: {
    [AccountHolderVerificationResultCode.EMAIL_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The email provided may be incorrect.",
    ],
  },

  Address: {
    [AccountHolderVerificationResultCode.ADDRESS_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "The address provided may be incorrect.",
    ],

    [AccountHolderVerificationResultCode.ADDRESS_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "The address provided matched.",
    ],

    [AccountHolderVerificationResultCode.ADDRESS_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The address provided did not match.",
    ],
  },

  "Fraud Check": {
    [AccountHolderVerificationResultCode.FRAUD_REVIEW]: [
      VerificationResultStatusForUI.WARNING,
      "The fraud check is under review.",
    ],

    [AccountHolderVerificationResultCode.FRAUD_RISK]: [
      VerificationResultStatusForUI.WARNING,
      "A potential fraud risk was discovered.",
    ],

    [AccountHolderVerificationResultCode.FRAUD_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "A potential fraud risk was discovered.",
    ],

    [AccountHolderVerificationResultCode.DENIED_FRAUD]: [
      VerificationResultStatusForUI.FAIL,
      "The verification failed due to fraud.",
    ],
  },

  "Government Watchlist": {
    [AccountHolderVerificationResultCode.WATCHLIST_HIT]: [
      VerificationResultStatusForUI.FAIL,
      "There was an OFAC match.",
    ],

    [AccountHolderVerificationResultCode.WATCHLIST_WARNING]: [
      VerificationResultStatusForUI.WARNING,
      "There was a potential OFAC match.",
    ],
  },

  "Know Your Customer": {
    [AccountHolderVerificationResultCode.DENIED_KYC]: [
      VerificationResultStatusForUI.FAIL,
      "Know Your Customer (KYC) checks failed.",
    ],
  },

  Phone: {
    [AccountHolderVerificationResultCode.PHONE_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "The phone number provided matched.",
    ],

    [AccountHolderVerificationResultCode.PHONE_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The phone number provided did not match.",
    ],
  },

  "Business Verification": {
    [AccountHolderVerificationResultCode.BUSINESS_VERIFICATION_SCORE_PASSED]: [
      VerificationResultStatusForUI.SUCCESS,
      "Business data was verified.",
    ],

    [AccountHolderVerificationResultCode.BUSINESS_VERIFICATION_SCORE_FAILED]: [
      VerificationResultStatusForUI.FAIL,
      "Business data did not pass verification.",
    ],
  },

  FEIN: {
    [AccountHolderVerificationResultCode.FEIN_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "FEIN matched.",
    ],

    [AccountHolderVerificationResultCode.FEIN_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "FEIN did not match.",
    ],
  },

  "Representative Match": {
    [AccountHolderVerificationResultCode.REPRESENTATIVE_MATCH]: [
      VerificationResultStatusForUI.SUCCESS,
      "The primary authorized person is verified as representative of the business.",
    ],

    [AccountHolderVerificationResultCode.REPRESENTATIVE_MISMATCH]: [
      VerificationResultStatusForUI.FAIL,
      "The primary authorized person could not be verified as representative of the business.",
    ],
  },

  // business: {
  //   fein: [
  //     AccountHolderVerificationResultCode.FEIN_DOCUMENT_REQUIRED,
  //     AccountHolderVerificationResultCode.FEIN_UNMATCHED,
  //     AccountHolderVerificationResultCode.FEIN_FOUND,
  //   ],

  //   name: [
  //     AccountHolderVerificationResultCode.KYC_BUSINESS_NAME_NOT_VERIFIED,
  //     AccountHolderVerificationResultCode.BUSINESS_NAME_UNMATCHED,
  //     AccountHolderVerificationResultCode.BUSINESS_NAME_MATCHED,
  //   ],
  // },
};

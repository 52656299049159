const isSupported = (() => {
  try {
    const testKey = "testKey1";
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
})();

export interface ActiveOrganizationStorage {
  getActiveOrganizationId: () => string | null;
  setActiveOrganizationId: (value: string) => void;
  setRedirectURL: (value: string) => void;
  getRedirectURL: () => string;
  getIsBannerDismissed: () => string | null;
  setIsBannerDismissed: () => void;
}

export function localStorageProxy(): ActiveOrganizationStorage {
  const inMemoryStorage: Record<string, string> = {};

  function getActiveOrganizationId(): string | null {
    if (isSupported) {
      return localStorage.getItem("activeOrganizationId");
    }
    if ("activeOrganizationId" in inMemoryStorage) {
      return inMemoryStorage.activeOrganizationId;
    }
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  function getRedirectURL(): string {
    if (isSupported) {
      const redirectURL = localStorage.getItem("RedirectURL");
      if (redirectURL !== null) {
        return decodeURIComponent(redirectURL);
      }
    }
    if ("RedirectURL" in inMemoryStorage) {
      const redirectURL = inMemoryStorage.RedirectURL;
      if (redirectURL) {
        return decodeURIComponent(redirectURL);
      }
    }
    return "";
  }

  function getIsBannerDismissed(): string | null {
    if (isSupported) {
      return localStorage.getItem("isBannerDismissed");
    }
    if ("isBannerDismissed" in inMemoryStorage) {
      return inMemoryStorage.isBannerDismissed;
    }
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  function setActiveOrganizationId(value: string): void {
    if (isSupported) {
      localStorage.setItem("activeOrganizationId", value);
    } else {
      // eslint-disable-next-line fp/no-mutation
      inMemoryStorage.activeOrganizationId = value;
    }
  }

  function setRedirectURL(value: string): void {
    if (isSupported) {
      localStorage.setItem("RedirectURL", value);
    } else {
      // eslint-disable-next-line fp/no-mutation
      inMemoryStorage.RedirectURL = value;
    }
  }

  function setIsBannerDismissed(): void {
    if (isSupported) {
      localStorage.setItem("isBannerDismissed", "true");
    } else {
      // eslint-disable-next-line fp/no-mutation
      inMemoryStorage.isBannerDismissed = "true";
    }
  }

  return {
    getActiveOrganizationId,
    setActiveOrganizationId,
    setRedirectURL,
    getRedirectURL,
    getIsBannerDismissed,
    setIsBannerDismissed,
  };
}

/* eslint-disable max-statements */
/* eslint-disable regexp/prefer-lookaround */
/* eslint-disable regexp/prefer-named-capture-group */
/* eslint-disable prefer-named-capture-group */

type ParsedUrlQuery = NodeJS.Dict<string[] | string>;

export const formatPhoneNumberSearch = (phone: string): string => {
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatSearchStringFromQuery = (
  query: Readonly<ParsedUrlQuery>,
): string => {
  if ("email" in query) {
    return `email: ${Array(query.email).flat()[0]}`;
  }
  if ("name" in query) {
    return `name: ${Array(query.name).flat()[0]}`;
  }
  if ("phone" in query) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `phone: ${formatPhoneNumberSearch(Array(query.phone).flat()[0]!)}`;
  }
  if ("legalBusinessName" in query) {
    return `nameBusiness: ${Array(query.legalBusinessName).flat()[0]}`;
  }

  if ("doingBusinessAsName" in query) {
    return `nameBusiness: ${Array(query.doingBusinessAsName).flat()[0]}`;
  }

  if ("authorizedPersonName" in query) {
    return `nameAuthorizedPerson: ${
      Array(query.authorizedPersonName).flat()[0]
    }`;
  }

  if ("idBusinessAccountHolder" in query) {
    return `idBusinessAccountHolder: ${
      Array(query.idBusinessAccountHolder).flat()[0]
    }`;
  }

  return "";
};

/* eslint-disable import/exports-last */
import Link from "next/link";
import React, { useContext } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";
import { CommonAppContext } from "@bay1/data";

import { TestOnly } from "./TestOnly";
import { ConditionalWrapper } from "./ConditionalWrapper";

interface SidebarLinkNavItem {
  text: string;
  href?: string;
  liveOnly?: boolean;
  isActiveLink?: boolean;
  userHasAccess: boolean;
  testOnly: boolean;
}

interface MainSidebarLinkNavItem extends SidebarLinkNavItem {
  icon: React.ReactElement<SVGElement>;
  iconActive: React.ReactElement<SVGElement>;
}

interface SecondarySidebarLinkNavItem extends SidebarLinkNavItem {
  icon: React.ReactElement<SVGElement>;
  iconActive: React.ReactElement<SVGElement>;
}

export const SidebarLink = (
  props: Readonly<MainSidebarLinkNavItem | SecondarySidebarLinkNavItem>,
): JSX.Element => {
  const {
    text,
    href,
    liveOnly,
    isActiveLink = false,
    userHasAccess = false,
    icon,
    iconActive,
    testOnly,
  } = props;

  const pageHref = userHasAccess ? href : undefined;
  const disabled = !(pageHref ?? "");
  const isSmall = "smallIcon" in props;

  const anchor = (
    <a
      className={classNames(
        "group flex items-center px-2 py-1 text-md rounded-rounded transform",
        {
          "text-black": !disabled,
          "cursor-not-allowed text-gray-400": disabled,
          "bg-ash rounded-full opacity-100": isActiveLink,
          "hover:bg-ash": !isActiveLink && !disabled,
          "py-1.5 text-xs": isSmall,
        },
      )}
      data-testid="sidebar::anchor"
      key={text}
    >
      <div
        className={classNames({
          "text-black mr-4 h-5 w-5 absolute": !isSmall,
          "opacity-100": !isActiveLink,
          "opacity-0": isActiveLink,
        })}
      >
        {icon}
      </div>
      <div
        className={classNames({
          "text-black mr-4 h-5 w-5": !isSmall,
          "opacity-0": !isActiveLink,
          "opacity-100": isActiveLink,
        })}
      >
        {iconActive}
      </div>

      <span className="truncate">{text}</span>

      {(liveOnly ?? false) && (
        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-ash text-black">
          Live Only
        </span>
      )}
    </a>
  );

  return (
    <ConditionalWrapper condition={testOnly} wrapper={TestOnly}>
      {pageHref === undefined ? (
        anchor
      ) : (
        <Link href={pageHref} key={text}>
          {anchor}
        </Link>
      )}
    </ConditionalWrapper>
  );
};

// eslint-disable-next-line complexity
const SidebarNav = ({
  children,
  isMobile,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Readonly<React.PropsWithChildren<{ isMobile: boolean }>>): JSX.Element => {
  const { pathname, query } = useRouter();
  const { user } = useContext(CommonAppContext);

  const { id: organizationIds } = query;
  const [organizationId] = Array(organizationIds).flat();

  const sidebarMainLinks: MainSidebarLinkNavItem[] = [
    {
      text: "Get Started Guide",
      href: `/organizations/${organizationId}/get-started`,
      isActiveLink: pathname === "/organizations/[id]/get-started",
      userHasAccess: user?.hasDashboardAccess ?? false,
      icon: <img alt="" src="/img/get-started.svg" />,
      iconActive: <img alt="" src="/img/get-started-active.svg" />,
      testOnly: true,
    },
    {
      text: "Home",
      href: `/organizations/${organizationId}/home`,
      isActiveLink: pathname === "/organizations/[id]/home",
      userHasAccess: user?.hasDashboardAccess ?? false,
      icon: <img alt="" src="/img/home-icon.svg" />,
      iconActive: <img alt="" src="/img/home-active-icon.svg" />,
      testOnly: false,
    },
    {
      text: "Account Holders",
      href: `/organizations/${organizationId}/account-holders`,

      isActiveLink:
        pathname === "/organizations/[id]/account-holders" ||
        pathname === "/organizations/[id]/business-account-holders",

      userHasAccess: user?.hasDashboardAccess ?? false,
      icon: <img alt="" src="/img/account-holder-icon.svg" />,
      iconActive: <img alt="" src="/img/account-holder-icon-active.svg" />,
      testOnly: false,
    },
    {
      text: "Spend Rules",
      href: `/organizations/${organizationId}/spend-rules`,
      isActiveLink: pathname === "/organizations/[id]/spend-rules",
      userHasAccess: user?.hasDashboardAccess ?? false,
      icon: <img alt="" src="/img/spend-rule.svg" />,
      iconActive: <img alt="" src="/img/spend-rule-active.svg" />,
      testOnly: false,
    },
  ];

  const sidebarSecondaryLinks: SecondarySidebarLinkNavItem[] = [
    {
      text: "Developers",
      href: `/organizations/${organizationId}/developer`,
      isActiveLink: pathname === "/organizations/[id]/developer",

      userHasAccess:
        (user?.hasDocsAccess ?? false) || (user?.hasDashboardAccess ?? false),

      icon: <img alt="" src="/img/api-keys-icon.svg" />,
      iconActive: <img alt="" src="/img/api-keys-active.svg" />,
      testOnly: false,
    },
    {
      text: "Organization",
      href: `/organizations/${organizationId}/settings`,

      isActiveLink:
        pathname === "/organizations/[id]/settings" ||
        pathname === "/organizations/[id]/team",

      userHasAccess:
        (user?.hasDocsAccess ?? false) || (user?.hasDashboardAccess ?? false),

      icon: <img alt="" src="/img/settings-icon.svg" />,
      iconActive: <img alt="" src="/img/settings-icon-active.svg" />,
      testOnly: false,
    },
  ];

  if (isMobile) {
    return (
      <div className="flex flex-col flex-grow pb-4 overflow-y-auto w-full lg:w-64">
        <div className="pt-0 lg:pt-5 flex-grow flex flex-col">
          <nav
            aria-label="Sidebar"
            className="flex-1 mt-0 lg:mt-4 px-0 md:px-2 space-y-8"
          >
            <div className="space-y-1.5" role="group">
              {sidebarMainLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
            {user?.hasDashboardAccess ?? false ? children : undefined}
            <div className="space-y-1.5">
              {sidebarSecondaryLinks.map((link) => (
                <SidebarLink key={link.text} {...link} />
              ))}
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className="hidden fixed h-full lg:flex lg:flex-shrink-0 z-20">
      <div className="flex flex-col w-64">
        <div className="flex flex-col flex-grow pb-4 overflow-y-auto w-full lg:w-64">
          <div className="pt-0 lg:pt-5 flex-grow flex flex-col">
            <nav
              aria-label="Sidebar"
              className="flex-1 mt-0 lg:mt-4 px-0 md:px-2 space-y-8 pb-48"
            >
              <div className="space-y-1" role="group">
                {sidebarMainLinks.map((link) => (
                  <SidebarLink key={link.text} {...link} />
                ))}
              </div>
              {user?.hasDashboardAccess ?? false ? children : undefined}

              <div className="space-y-1">
                {sidebarSecondaryLinks.map((link) => (
                  <SidebarLink key={link.text} {...link} />
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarNav;

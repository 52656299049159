import { CardProductVertical } from "@bay1/sdk/generated/graphql";

export const REFRESH_TOKEN = "REFRESH-ACCESS-TOKEN";

export const CardFeatureMap: Record<string, string> = {
  CreditPaymentCardFinancialAccountFeature: "Credit Card",
  DebitPaymentCardFinancialAccountFeature: "Debit Card",
  DirectDepositFinancialAccountFeature: "Direct Deposit",
  JustInTimeFundingFinancialAccountFeature: "Just-in-Time Funding",
  NegativeBalanceReserveFinancialAccountFeature: "Negative Balance Reserve",
  PayrollAdvanceFinancialAccountFeature: "Payroll Advance",
  PayrollEmployerAdvanceFinancialAccountFeature: "Employer Payroll Advance",
  PointRewardFinancialAccountFeature: "Point Rewards",
  PrePaidPaymentCardFinancialAccountFeature: "Prepaid Card",
  ProductFundingFinancialAccountFeature: "Funds the Product",
  ProductReserveFinancialAccountFeature: "Reserve Account",
  SecuredCreditPaymentCardFinancialAccountFeature: "Secured Credit",
};

export const accountVerticals = [
  CardProductVertical.AP_INVOICE_AUTOMATION,
  CardProductVertical.EARNED_WAGE_ACCESS,
  CardProductVertical.GENERAL_PURPOSE_RELOADABLE,
];

export const businessVerticals = [
  CardProductVertical.SECURED_COMMERCIAL_CREDIT,
];

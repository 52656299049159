/* eslint-disable regexp/prefer-lookaround */
/* eslint-disable regexp/prefer-named-capture-group */
/* eslint-disable prefer-named-capture-group */
/* eslint-disable max-lines */

/* eslint-disable import/exports-last */
import type {
  TransactionEventResponseCode,
  Maybe,
  Name,
  LedgerName,
  Address,
  PersonAccountHolderNameFilterInput,
  BusinessName,
  BusinessTitle,
  Phone,
  BusinessStructure,
} from "@bay1/sdk/generated/graphql";
import {
  CardFormFactor,
  CardProductVertical,
  CardUsage,
  PaymentCardStatus,
  PaymentCardShippingMethod,
  PhoneLabel,
  AccountHolderApplicationStatusCode,
  AccountHolderVerificationStatusCode,
  AccountHolderVerificationStatusReasonCode,
  NotificationTargetStatus,
  TransactionEventFilter,
} from "@bay1/sdk/generated/graphql";
import { format } from "date-fns";
import title from "title";
import type { DeepReadonly } from "ts-essentials";

import type { TransactionEventNode } from "../../hooks/useCardProductTransactionEventsInfinite";
import type { USAccountHolderFromQuery } from "../../hooks/useFindAccountHolder";
import type { PartialMoneyFilterInput } from "../filters/TransactionEventFilterDropdown";

// eslint-disable-next-line import/no-unused-modules
export type { VerificationResultMap } from "./formatAccountHolderCardProductApplicationStatuses";

export {
  verificationResultMap,
  VerificationResultStatusForUI,
} from "./formatAccountHolderCardProductApplicationStatuses";

export function formatTransactionEventName(
  transactionEvent: Maybe<TransactionEventNode>,
): string {
  if (transactionEvent === undefined) {
    return "";
  }

  if (
    transactionEvent.transaction?.__typename === "CreditTransaction" &&
    transactionEvent.__typename === "ClearingEvent"
  ) {
    return "Refund";
  }

  if (transactionEvent.__typename === "AuthorizationAndClearEvent") {
    return "Authorization and Clear";
  }

  if (transactionEvent.__typename === "BalanceInquiryEvent") {
    return "Balance Inquiry";
  }

  return transactionEvent.__typename.replace("Event", "");
}

export function formatTransactionEventResponseCode(
  responseCode: TransactionEventResponseCode,
): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return title(responseCode.replaceAll("_", " "));
}

export const formatCardUsage = (usage: Maybe<CardUsage>): string => {
  switch (usage) {
    case CardUsage.MULTI_USE:
      return "Multi-use";
    case CardUsage.SINGLE_USE:
      return "Single-use";

    default:
      return "";
  }
};

export const formatCardVertical = (
  vertical: Maybe<CardProductVertical>,
): string => {
  switch (vertical) {
    case CardProductVertical.AP_INVOICE_AUTOMATION:
      return "Accounts Payable Invoice Automation";
    case CardProductVertical.EARNED_WAGE_ACCESS:
      return "Earned Wage Access";
    case CardProductVertical.GENERAL_PURPOSE_RELOADABLE:
      return "General Purpose Reloadable";

    default:
      return "";
  }
};

export const formatSpendRuleTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRule":
      return "Category Code";
    case "MerchantCountrySpendRule":
      return "Country Code";
    case "AmountLimitSpendRule":
      return "Amount";
    case "CVVSpendRule":
      return "CVV";
    case "StreetAddressSpendRule":
      return "Street Address";
    case "MerchantIdentifierSpendRule":
      return "Merchant Identifier";

    default:
      return "";
  }
};

export const formatSpendRuleResultTypes = (string: Maybe<string>): string => {
  switch (string) {
    case "MerchantCategorySpendRuleResult":
      return "Category Code";
    case "MerchantCountrySpendRuleResult":
      return "Country Code";
    case "AmountLimitSpendRuleResult":
      return "Amount";
    case "CVVSpendRuleResult":
      return "CVV";
    case "StreetAddressSpendRuleResult":
      return "Street Address";
    case "MerchantIdentifierSpendRuleResult":
      return "Merchant Identifier";

    default:
      return "";
  }
};

export const composeAccountHolderName = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle ?? ""} ${givenName} ${middleName ?? ""} ${familyName} ${
    suffix ?? ""
  }`;
};

export const composeBusinessAccountHolderName = (
  name: Maybe<BusinessName>,
): string => {
  if (!name) {
    return "";
  }

  const { doingBusinessAsName, legalBusinessName } = name;

  if (doingBusinessAsName === "") {
    return legalBusinessName;
  }

  return doingBusinessAsName ?? legalBusinessName;
};

const businessStructureTransforms: Record<
  "LLC",
  (businessStructure: BusinessStructure) => string
> = {
  LLC: () => "LLC",
};

type businessStructureKey = keyof typeof businessStructureTransforms;

export const presentBusinessStructure = (
  businessStructure?: BusinessStructure,
): string | undefined => {
  if (!businessStructure) {
    return undefined;
  }

  const shouldTransform = Object.keys(businessStructureTransforms).includes(
    businessStructure,
  );

  if (shouldTransform) {
    return businessStructureTransforms[
      businessStructure as businessStructureKey
    ](businessStructure);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return title(businessStructure.replaceAll("_", " "));
};

const jobTitleTransforms: Record<
  "CEO" | "CFO" | "COO",
  (jobTitle: BusinessTitle) => string
> = {
  CEO: () => "CEO",
  CFO: () => "CFO",
  COO: () => "COO",
};

type jobTitleKey = keyof typeof jobTitleTransforms;

export const presentJobTitle = (businessTitle?: BusinessTitle): string => {
  if (!businessTitle) {
    return "";
  }

  const shouldTransform =
    Object.keys(jobTitleTransforms).includes(businessTitle);

  if (shouldTransform) {
    return jobTitleTransforms[businessTitle as jobTitleKey](businessTitle);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  return title(businessTitle.replaceAll("_", " "));
};

export const presentDateOfBirth = (dateOfBirth?: string) =>
  dateOfBirth === undefined
    ? "N/A"
    : format(new Date(dateOfBirth.replaceAll("-", "/")), "MMM do, yyyy");

export const formatPhoneNumberLabel = (
  phoneNumbers: Readonly<Maybe<Phone[]>>,
) => ((phoneNumbers || []).length > 1 ? "Phone Numbers" : "Phone Number");

export const formatPercentage = (value?: number) => {
  const percentSymbol = (value ?? "") === "" ? "" : "%";
  return `${value ?? ""}${percentSymbol}`;
};

export const presentAccountHolderType = (
  accountHolder: USAccountHolderFromQuery,
): string => {
  if (accountHolder?.__typename === "USPersonAccountHolder") {
    return "Person";
  }

  if (accountHolder?.__typename === "USBusinessAccountHolder") {
    return "Business";
  }

  return "";
};

export const composeLinePersonalization = (
  name: Maybe<Name> | undefined,
): string => {
  if (!name) {
    return "";
  }

  const { givenName, familyName, suffix } = name;

  if (suffix !== undefined && suffix !== "") {
    return `${givenName} ${familyName} ${suffix}`;
  }

  return `${givenName} ${familyName}`;
};

export const composeAddress = (address: Maybe<Address>): string => {
  if (!address) {
    return "";
  }

  const { streetAddress, extendedAddress, postalCode, region, locality } =
    address;

  if (extendedAddress !== "") {
    return `${streetAddress} ${extendedAddress}, ${locality}, ${region} ${postalCode}`;
  }

  return `${streetAddress}, ${locality}, ${region} ${postalCode}`;
};

export const composeLocation = (address?: Address) => {
  if (!address) {
    return undefined;
  }

  const locality = address.locality ?? "";
  const separator = locality === "" ? "" : ", ";

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return `${title(locality)}${separator}${address.region ?? ""}`;
};

export const formatMerchantDetail = (
  detail: Maybe<string>,
  defaultValue = "N/A",
): string =>
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
  detail || defaultValue;

export const formatAccountHolderApplicationStatusCode = (
  status?: AccountHolderApplicationStatusCode,
): string => {
  switch (status) {
    case AccountHolderApplicationStatusCode.APPROVED:
      return "Approved";
    case AccountHolderApplicationStatusCode.IN_REVIEW:
      return "In Review";
    case AccountHolderApplicationStatusCode.PENDING:
      return "Pending";
    case AccountHolderApplicationStatusCode.DENIED:
      return "Denied";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationStatusCode = (
  status?: AccountHolderVerificationStatusCode,
): string => {
  switch (status) {
    case AccountHolderVerificationStatusCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusCode.DENIED:
      return "Denied";

    default:
      return "";
  }
};

export const formatAccountHolderVerificationReason = (
  reason?: AccountHolderVerificationStatusReasonCode,
): string => {
  switch (reason) {
    case AccountHolderVerificationStatusReasonCode.DENIED:
      return "Denied";
    case AccountHolderVerificationStatusReasonCode.DOCUMENT_UPLOAD_REQUIRED:
      return "Document Upload(s) Required";
    case AccountHolderVerificationStatusReasonCode.IN_REVIEW:
      return "In Review";
    case AccountHolderVerificationStatusReasonCode.KBA_REQUIRED:
      return "KBA Required";
    case AccountHolderVerificationStatusReasonCode.PASSED:
      return "Passed";
    case AccountHolderVerificationStatusReasonCode.PENDING:
      return "Pending";
    case AccountHolderVerificationStatusReasonCode.REVIEW_REQUIRED:
      return "Review Required";

    default:
      return "";
  }
};

export const formatPhoneLabel = (label?: PhoneLabel): string => {
  switch (label) {
    case PhoneLabel.HOME:
      return "Home";
    case PhoneLabel.MOBILE:
      return "Mobile";
    case PhoneLabel.WORK:
      return "Work";

    default:
      return "";
  }
};

export const formatPaymentCardStatus = (status?: PaymentCardStatus): string => {
  switch (status) {
    case PaymentCardStatus.ACTIVATION_REQUIRED:
      return "Activation Required";
    case PaymentCardStatus.ACTIVE:
      return "Active";
    case PaymentCardStatus.SUSPENDED:
      return "Suspended";
    case PaymentCardStatus.CLOSED:
      return "Closed";

    default:
      return "";
  }
};

export const formatNotificationTargetStatus = (
  status?: NotificationTargetStatus,
): string => {
  switch (status) {
    case NotificationTargetStatus.ACTIVE:
      return "Active";
    case NotificationTargetStatus.DEACTIVATED:
      return "Deactivated";
    case NotificationTargetStatus.PENDING_VERIFICATION:
      return "Pending";
    case NotificationTargetStatus.ACTIVATION_FAILED:
      return "Activation Failed";

    default:
      return "";
  }
};

export const formatTransactionEventFilter = (
  status?: TransactionEventFilter,
): string => {
  switch (status) {
    case TransactionEventFilter.AUTHORIZATION_EVENT:
      return "Authorization";
    case TransactionEventFilter.REVERSAL_EVENT:
      return "Reversal";
    case TransactionEventFilter.CLEARING_EVENT:
      return "Clearing";

    default:
      return "";
  }
};

export const formatShippingMethod = (
  method: Maybe<PaymentCardShippingMethod>,
): string => {
  switch (method) {
    case PaymentCardShippingMethod.USPS_GROUND:
      return "USPS Ground";
    case PaymentCardShippingMethod.USPS_PRIORITY:
      return "USPS Priority";
    case PaymentCardShippingMethod.USPS_EXPRESS:
      return "USPS Express";
    case PaymentCardShippingMethod.UPS_GROUND:
      return "UPS Ground";
    case PaymentCardShippingMethod.UPS_SECOND_DAY:
      return "UPS Second Day";
    case PaymentCardShippingMethod.UPS_NEXT_DAY:
      return "UPS Next Day";

    default:
      return "";
  }
};

export const formatFormFactor = (factor?: CardFormFactor): string => {
  switch (factor) {
    case CardFormFactor.VIRTUAL:
      return "Virtual";
    case CardFormFactor.PHYSICAL:
      return "Physical";

    default:
      return "";
  }
};

export const formatLedgerName = (name: Maybe<LedgerName>): string =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  name ? title(name.replaceAll("_", " ")) : "";

export const formatAmount = (number: number): string =>
  (number / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatRequestedAmountSymbol = (
  string: Maybe<keyof PartialMoneyFilterInput>,
): string => {
  switch (string) {
    case "equals":
      return "= ";
    case "greaterThan":
      return "> ";
    case "greaterThanOrEquals":
      return ">= ";
    case "lessThan":
      return "< ";
    case "lessThanOrEquals":
      return "<= ";

    default:
      return "";
  }
};

// Initial code taken from commit 4bf87c0, updated via our linting rules.
export const formatSSN = (ssn: string): string => {
  const formattedSSN = ssn
    .replace(/\D/gu, "")
    .replace(/^(\d{3})/u, "$1-")
    .replace(/-(\d{2})/u, "-$1-")
    .replace(/(\d)-(\d{4}).*/u, "$1-$2");
  return formattedSSN.slice(0, 11);
};

export const formatPhoneNumber = (phone: Maybe<string>): string => {
  if (phone === undefined) {
    return "";
  }
  const formattedPhoneNumber = phone
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{3})-?(\d{1,3})/u, "$1-$2")
    .replace(/^(\d{3})-?(\d{3})-?(\d{1,4})/u, "$1-$2-$3");
  return formattedPhoneNumber.slice(0, 12);
};

export const formatPostalCode = (code: string): string => {
  const formattedPostalCode = code
    .replace(/[^\d-]/gu, "")
    .replace(/^(\d{5})-?(\d{1,4})/u, "$1-$2");
  return formattedPostalCode.slice(0, 10);
};

export const composeAccountHolderNameFromFilter = (
  name: DeepReadonly<PersonAccountHolderNameFilterInput> | undefined,
): string => {
  if (!name) {
    return "";
  }
  const { givenName, middleName, familyName, title: nameTitle, suffix } = name;

  return `${nameTitle?.soundsLike ?? ""} ${givenName?.soundsLike ?? ""} ${
    middleName?.soundsLike ?? ""
  } ${familyName?.soundsLike ?? ""} ${suffix?.soundsLike ?? ""}`;
};

export const formatEmployerIdentificationNumber = (value: string): string => {
  const employerIdentificationNumber = value.replace(/\D/gu, "");

  const employerIdentificationNumberLength =
    employerIdentificationNumber.length;

  if (employerIdentificationNumberLength < 3) {
    return employerIdentificationNumber;
  }

  return `${employerIdentificationNumber.slice(
    0,
    2,
  )}-${employerIdentificationNumber.slice(2)}`;
};

export const formatAccountHolderNameFilterInput = (
  name: string,
): Maybe<PersonAccountHolderNameFilterInput> => {
  const splitBySpaces = name.split(" ");

  if (splitBySpaces.length === 5) {
    return {
      title: { soundsLike: [splitBySpaces[0]] },
      givenName: { soundsLike: [splitBySpaces[1]] },
      middleName: { soundsLike: [splitBySpaces[2]] },
      familyName: { soundsLike: [splitBySpaces[3]] },
      suffix: { soundsLike: [splitBySpaces[4]] },
    };
  }

  if (splitBySpaces.length === 2) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      familyName: { soundsLike: [splitBySpaces[1]] },
    };
  }

  if (splitBySpaces.length === 1) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
    };
  }

  if (splitBySpaces.length === 3) {
    return {
      givenName: { soundsLike: [splitBySpaces[0]] },
      middleName: { soundsLike: [splitBySpaces[1]] },
      familyName: { soundsLike: [splitBySpaces[2]] },
    };
  }

  return undefined;
};

export const formatExpirationDate = (expirationDate: Maybe<string>) => {
  if (expirationDate === undefined) {
    return "";
  }

  const [year, month] = expirationDate.split("T")[0].split("-");

  return `${month}/${year.slice(-2)}`;
};

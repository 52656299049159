// eslint-disable-next-line @typescript-eslint/no-shadow
import Image from "next/image";
import type { PropsWithChildren } from "react";
import { useContext } from "react";
import Link from "next/link";
import { CommonAppContext } from "@bay1/data";
import { useRouter } from "next/router";

import { AccountDesktopNav } from "../AccountNav";
import { SearchBar } from "../SearchBar";

import { DocumentSearchContainer } from "./DocumentSearchContainer";

export const NavBarLayout = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);
  const router = useRouter();
  return (
    <div className="flex justify-between items-center px-2 py-2 pr-3">
      {router.pathname.includes("/organizations") ? (
        <div className="flex-none text-reset mr-2 sm:mr-6">
          <Link
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            href={{
              pathname: "/",

              query: {
                activeOrganizationId: activeOrganization?.id,
              },
            }}
            passHref
          >
            <a>
              <span className="sr-only">Highnote</span>
              <span className="hidden sm:block">
                <Image
                  alt="Highnote"
                  className="hidden sm:block"
                  height={56}
                  src="/img/highnote.svg"
                  width={162}
                />
              </span>
              <span className="sm:hidden">
                <Image
                  alt="Highnote"
                  className="sm:hidden"
                  height={40}
                  src="/img/highnote-circle.svg"
                  width={40}
                />
              </span>
            </a>
          </Link>
        </div>
      ) : (
        <div className="flex-none text-reset mr-6">
          <Link
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            href={{
              pathname: "/",

              query: {
                activeOrganizationId: activeOrganization?.id,
              },
            }}
            passHref
          >
            <a>
              <span className="sr-only">Highnote</span>
              <Image
                alt="Highnote"
                className=""
                height={56}
                src="/img/highnote.svg"
                width={162}
              />
            </a>
          </Link>
        </div>
      )}
      {router.pathname.includes("/organizations") && (
        <div className="flex justify-between w-full items-center pr-9 sm:pr-2 md:ml-14 md:pl-1.5 rounded">
          <SearchBar />
          {children ?? undefined}
          <AccountDesktopNav />
        </div>
      )}
      {router.pathname.includes("/docs") && (
        <div className="flex justify-between w-full items-center pr-9 sm:pr-2 md:ml-14 md:pl-1.5 rounded">
          <DocumentSearchContainer />
          {children ?? undefined}
          <AccountDesktopNav />
        </div>
      )}
      {!router.pathname.includes("/docs") &&
        !router.pathname.includes("/organizations") && (
          <>
            {children ?? undefined}
            <AccountDesktopNav />
          </>
        )}
    </div>
  );
};

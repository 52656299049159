/* eslint-disable react/jsx-no-useless-fragment */

import { PageLoader } from "@bay1/ui";
import { DASHBOARD_URL, MARKETING_URL } from "@bay1/ui/urlHelper";
import type { PropsWithChildren } from "react";
import { useContext } from "react";
import { CommonAppContext } from "@bay1/data";
import { setTags, setUser } from "@sentry/node";
import { useRouter } from "next/router";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";

// eslint-disable-next-line max-statements, complexity
const SignedIn = ({
  children,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const { user, loading, organizations, activeOrganization, localStore } =
    useContext(CommonAppContext);

  const router = useRouter();

  const { id: organizationIds } = router.query;

  const [organizationId] = Array(organizationIds).flat();

  if (loading) {
    return <PageLoader />;
  }

  if (!user) {
    // eslint-disable-next-line fp/no-mutation
    window.location.href = `${DASHBOARD_URL}/auth/signin?RedirectURL=${encodeURI(
      window.location.href,
    )}`;

    return <></>;
  }

  setTags({
    hasDashboardAccess: user.hasDashboardAccess,
    hasDocsAccess: user.hasDocsAccess,
  });

  setUser({ email: (user.email ?? "") || "", name: user.name });

  const referrer = localStore?.getRedirectURL() ?? "";
  if (referrer.length > 0) {
    localStore?.setRedirectURL("");
    // eslint-disable-next-line fp/no-mutation
    window.location.href = decodeURIComponent(referrer);
  }

  if (user.hasDashboardAccess ?? false) {
    if (router.pathname.includes("/auth/organizations")) {
      return <>{children}</>;
    }
    if (organizations && organizations.length > 0) {
      if (
        activeOrganization?.id !== undefined &&
        organizationId === undefined
      ) {
        void router.push(
          activeOrganization.profile.environment === IntegrationEnvironment.TEST
            ? `organizations/${activeOrganization.id}/get-started`
            : `organizations/${activeOrganization.id}/home`,
        );
      } else if (
        activeOrganization?.id === undefined &&
        organizationId === undefined
      ) {
        void router.push(`organizations/${organizations[0].id}/home`);
      } else {
        return <>{children}</>;
      }
    }

    return <PageLoader />;
  }

  if (user.hasDocsAccess ?? false) {
    if (
      router.pathname.endsWith("/developer") ||
      router.pathname.endsWith("/settings") ||
      router.pathname.endsWith("/team")
    ) {
      if (organizations && organizations.length > 0) {
        return <>{children}</>;
      }

      return <PageLoader />;
    }

    // eslint-disable-next-line fp/no-mutation
    window.location.href = `${MARKETING_URL}/docs`;

    return <></>;
  }

  // eslint-disable-next-line fp/no-mutation
  window.location.href = "/comingSoon";

  return <></>;
};

export default SignedIn;

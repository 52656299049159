/* eslint-disable max-lines */
import { useCallback, useContext, useState, useEffect } from "react";
import type { SyntheticEvent } from "react";
import { Transition } from "@headlessui/react";
import { CommonAppContext } from "@bay1/data";
import type { SessionUser } from "@bay1/data/CommonAppContext";
import Link from "next/link";
import { useRouter } from "next/router";
import { IntegrationEnvironment } from "@bay1/sdk/generated/graphql";

import { DASHBOARD_URL, MARKETING_URL } from "../urlHelper";

import Avatar from "./Avatar";

async function getCsrfToken(): Promise<string | undefined> {
  const response = await fetch(`${DASHBOARD_URL}/api/auth/csrf`, {
    credentials: "include",
  });

  if (response.ok) {
    const { csrfToken } = (await response.json()) as { csrfToken: string };

    return csrfToken;
  }

  return undefined;
}

async function signOut(): Promise<void> {
  const clientId = encodeURIComponent(
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ?? "",
  );
  const returnTo = encodeURIComponent(`${DASHBOARD_URL}/auth/signin`);

  const body = new URLSearchParams();

  body.append("json", "true");
  body.append(
    "callbackUrl",
    `https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/v2/logout?clientId=${clientId}&returnTo=${returnTo}`,
  );
  body.append("csrfToken", (await getCsrfToken()) ?? "");

  const response = await fetch(`${DASHBOARD_URL}/api/auth/signout`, {
    method: "POST",
    credentials: "include",

    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },

    body,
  });

  if (response.ok) {
    const data = (await response.json()) as { url: Location };

    // eslint-disable-next-line fp/no-mutation
    window.location = data.url;
  }
}

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types, complexity
export const UserMenu = ({
  user,
  disabled,
}: {
  user: SessionUser;
  disabled?: boolean;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    organizations,
    activeOrganization,
    currentOrganizationId,
    localStore,
  } = useContext(CommonAppContext);
  const router = useRouter();

  const handleSetIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOnBlur = useCallback(
    () =>
      setTimeout(() => {
        setIsOpen(false);
      }, 100),
    [],
  );

  useEffect(() => {
    localStore?.setActiveOrganizationId(currentOrganizationId ?? "");
  }, [currentOrganizationId, localStore]);

  const handleChangeOrganizations = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: SyntheticEvent<HTMLButtonElement>) => {
      if (router.pathname === "/") {
        // eslint-disable-next-line fp/no-mutation
        window.location.href = `${MARKETING_URL}/?activeOrganizationId=${event.currentTarget.value}`;
      } else if (router.pathname.includes("/docs")) {
        // eslint-disable-next-line fp/no-mutation
        window.location.href = `${MARKETING_URL}/docs?activeOrganizationId=${event.currentTarget.value}`;
      } else {
        // eslint-disable-next-line fp/no-mutation
        window.location.href =
          event.currentTarget.id === IntegrationEnvironment.TEST
            ? `${DASHBOARD_URL}/organizations/${event.currentTarget.value}/get-started`
            : `${DASHBOARD_URL}/organizations/${event.currentTarget.value}/home`;
      }
    },
    [router.pathname],
  );

  const handleSignout = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    async (event: SyntheticEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      await signOut();
    },
    [],
  );

  return (
    <>
      {(!router.pathname.includes("/docs") ||
        router.pathname.includes("/reference")) &&
        user.hasDocsAccess && (
          <Link
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            href={{
              pathname: `${MARKETING_URL}/docs/`,

              query: {
                activeOrganizationId: activeOrganization?.id,
              },
            }}
            passHref
          >
            <a
              className="hidden md:block text-sm cursor-pointer hover:underline transform duration-200 ease-in-out"
              data-testid="user-menu-desktop::documentation"
            >
              <span className="hidden xl:block">Documentation</span>
              <span className="hidden lg:block xl:hidden">Docs</span>
            </a>
          </Link>
        )}
      {!router.pathname.includes("/organizations") && user.hasDashboardAccess && (
        <Link
          // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
          href={{
            pathname:
              activeOrganization?.profile.environment ===
              IntegrationEnvironment.TEST
                ? `${DASHBOARD_URL}/organizations/${activeOrganization.id}/get-started`
                : `${DASHBOARD_URL}/organizations/${activeOrganization?.id}/home`,

            query: { activeOrganizationId: activeOrganization?.id },
          }}
          passHref
        >
          <a
            className="hidden lg:block text-sm cursor-pointer hover:underline transform duration-200 ease-in-out"
            data-testid="user-menu-desktop::dashboard"
          >
            Dashboard{" "}
          </a>
        </Link>
      )}
      <span className="ml-0 lg:ml-4 relative z-0 inline-flex rounded-sm">
        <button
          aria-expanded="false"
          aria-haspopup="true"
          className="w-56 h-10 group cursor-default lg:cursor-pointer bg-ash border border-transparent lg:hover:border-black lg:focus:ring-1 ring-black rounded-full px-1 text-xs text-left"
          data-testid="user-menu-desktop::open"
          disabled={!organizations || disabled}
          onBlur={handleOnBlur}
          onClick={handleSetIsOpen}
          type="button"
        >
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-1.5">
              <span className="inline-block relative">
                <span className="transform block rounded-full">
                  <Avatar user={user} />
                </span>
              </span>
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-xs truncate">
                  <span className="text-xxs rounded-full uppercase font-medium px-1 mr-1 bg-black bg-opacity-10 tracking-wide">
                    {activeOrganization?.profile.environment}
                  </span>
                  {activeOrganization?.profile.displayName}
                </span>
              </span>
            </span>
            {/* Heroicon name: chevron-down */}
            <svg
              aria-hidden="true"
              className="hidden flex-shrink-0 mr-1 h-5 w-5 lg:block"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                fillRule="evenodd"
              />
            </svg>
          </span>
        </button>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          show={isOpen}
        >
          <div className="relative inline-block text-left">
            <div
              aria-labelledby="menu-button"
              aria-orientation="vertical"
              className="origin-top-right absolute right-0 mt-8 w-64 rounded-rounded shadow-lg bg-white ring-1 ring-ash divide-y divide-ash focus:outline-none"
              role="menu"
              tabIndex={-1}
            >
              {(user.hasDashboardAccess ?? false) && (
                <div className="py-1" role="none">
                  {organizations?.map((organization) => (
                    <Link
                      href={
                        // eslint-disable-next-line no-nested-ternary
                        router.pathname.includes("/organizations")
                          ? organization.profile.environment ===
                            IntegrationEnvironment.TEST
                            ? `${DASHBOARD_URL}/organizations/${organization.id}/get-started`
                            : `${DASHBOARD_URL}/organizations/${organization.id}/home`
                          : `/${router.pathname.split("/")[1]}`
                      }
                      key={organization.id}
                    >
                      <button
                        className="block relative w-full truncate text-left pl-2.5 py-2.5 pr-6 text-xs hover:bg-bone"
                        data-testid="user-menu-desktop::select::organization"
                        id={organization.profile.environment}
                        onClick={handleChangeOrganizations}
                        type="button"
                        value={organization.id}
                      >
                        <span className="text-xxs rounded-full uppercase font-medium px-1 mr-1 bg-black bg-opacity-10 tracking-wide">
                          {organization.profile.environment}
                        </span>
                        {organization.profile.displayName}
                        {activeOrganization?.id === organization.id && (
                          <div className="absolute right-2.5 top-4 h-2 w-2 rounded-full bg-green inline-flex" />
                        )}
                      </button>
                    </Link>
                  ))}
                </div>
              )}

              {!(user.hasDashboardAccess ?? false) && user.hasDocsAccess && (
                <div className="py-1" role="none">
                  <Link
                    href={`${DASHBOARD_URL}/organizations/${activeOrganization?.id}/developer`}
                  >
                    <a
                      className="block px-2.5 py-2.5 rounded-t text-xs hover:bg-bone"
                      data-testid="user-menu-desktop::apiKeys"
                      role="menuitem"
                    >
                      API Keys
                    </a>
                  </Link>
                  <Link
                    href={`${DASHBOARD_URL}/organizations/${activeOrganization?.id}/settings`}
                  >
                    <a
                      className="block px-2.5 py-2.5 rounded-t text-xs hover:bg-bone"
                      data-testid="user-menu-desktop::orgSettings"
                      role="menuitem"
                    >
                      Organization Settings
                    </a>
                  </Link>
                </div>
              )}

              <div className="py-1" role="none">
                <a
                  className="block px-2.5 py-2.5 text-xs hover:bg-bone"
                  data-testid="user-menu-desktop::signout"
                  href={DASHBOARD_URL}
                  onClick={handleSignout}
                  role="menuitem"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        </Transition>
      </span>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const UserMobileMenu = ({
  user,
}: {
  user: SessionUser;
}): JSX.Element => {
  const { activeOrganization, organizations } = useContext(CommonAppContext);

  const handleSignoutClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    async (event: SyntheticEvent<HTMLAnchorElement>): Promise<void> => {
      event.preventDefault();
      await signOut();
    },
    [],
  );

  return (
    <>
      <div className="py-6 border-t">
        <div className="grid gap-4">
          <a
            className="rounded-md text-base"
            data-testid="user-menu-mobile::marketing"
            href={String(MARKETING_URL)}
          >
            highnoteplatform.com
          </a>
          {(user.hasDocsAccess ?? false) && (
            <Link
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              href={{
                pathname: `${MARKETING_URL}/docs/`,

                query: {
                  activeOrganizationId: activeOrganization?.id,
                },
              }}
              passHref
            >
              <a
                className="rounded-md text-base"
                data-testid="user-menu-mobile::documentation"
                href={`${MARKETING_URL}/docs`}
              >
                Documentation
              </a>
            </Link>
          )}

          {(user.hasDashboardAccess ?? false) && (
            <Link
              // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
              href={{
                pathname:
                  activeOrganization?.profile.environment ===
                  IntegrationEnvironment.TEST
                    ? `${DASHBOARD_URL}/organizations/${activeOrganization.id}/get-started`
                    : `${DASHBOARD_URL}/organizations/${activeOrganization?.id}/home`,

                query: { activeOrganizationId: activeOrganization?.id },
              }}
              passHref
            >
              <a
                className="rounded-md text-base"
                data-testid="user-menu-mobile::dashboard"
                href={String(DASHBOARD_URL)}
              >
                Dashboard
              </a>
            </Link>
          )}
        </div>
      </div>

      <div className="block md:inline-flex">
        <UserMenu disabled user={user} />
        <p className="flex md:inline-flex md:ml-4 md:px-2 py-2 text-base">
          {(user.hasDashboardAccess ?? false) &&
            organizations &&
            organizations.length > 1 && (
              <Link href={`${DASHBOARD_URL}/auth/organizations`} passHref>
                <a
                  className="underline mr-4"
                  data-testid="user-menu-mobile::organizationSwitcher"
                >
                  Switch Organization
                </a>
              </Link>
            )}
          <Link href="/" passHref>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <a
              className="underline"
              data-testid="user-menu-mobile::signout"
              onClick={handleSignoutClick}
            >
              Sign Out
            </a>
          </Link>
        </p>
      </div>
    </>
  );
};

/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable fp/no-mutating-methods */
import {
  AvsResponseCode,
  CvvResponseCode,
  Iso3166Alpha3Country,
  MerchantCategory,
} from "@bay1/sdk/generated/graphql";
import type {
  CardProductTransactionEventsFilterInput,
  Maybe,
  SpendRule,
  SpendRuleConnection,
  TimestampFilterInputRange,
} from "@bay1/sdk/generated/graphql";
import type { ClientError } from "graphql-request";
import type { GraphQLError } from "graphql-request/dist/types";
import type { DeepReadonly } from "ts-essentials";
import {
  endOfDay,
  endOfToday,
  endOfYesterday,
  lastDayOfMonth,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfYear,
  startOfYesterday,
  subMonths,
  subWeeks,
  compareAsc,
} from "date-fns";

import type { SpendRulesStatus } from "../components/create/spendRule/AttachDetachSpendRuleToCardProduct";
import type { Category } from "../components/create/spendRule/CreateSpendRule";
import { formatAmount } from "../components/formatters";
import type { PartialMoneyFilterInput } from "../components/filters/TransactionEventFilterDropdown";
import type { SpendRuleFromQuery } from "../hooks/useFindSpendRule";
import type { FinancialAccount } from "../hooks/useFinancialAccount";

import { CardFeatureMap } from "./constants";

const BAD_CREDENTIALS = "Bad credentials";

const CLIENT_ERROR_TAG = "[ClientError]";

const currentYear = new Date().getFullYear();
const minimumYear = currentYear - 13;
const maxYear = currentYear - 125;

const currentDate = new Date();

export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong, please try again. If the problem persists, contact support@highnoteplatform.com.";

export const INVALID_FIELDS_ERROR_MESSAGE =
  "Some fields were invalid. Please update them and try again. If the problem persists, contact support@highnoteplatform.com.";

export const AUTHENTICATION_INVALID_MESSAGE =
  "Authentication Error. Please login or contact support@highnoteplatform.com.";

export const restrictValueToDigits = (value: string): string =>
  value.replace(/\D/gu, "");

export const restrictValueToDigitsWithDashes = (value: string): string =>
  value.replace(/[^\d-]/gu, "");

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const isAccessDeniedError = (error: ClientError): Maybe<GraphQLError> =>
  error.response.errors?.find((graphqlError) =>
    graphqlError.message.includes(BAD_CREDENTIALS),
  );

export const generateClientErrorMessage = (message: string): string =>
  `${CLIENT_ERROR_TAG}: ${message}`;

export const generateRandomEmail = (string = ""): string => {
  const chars = "abcdefghijklmnopqrstuvwxyz1234567890";

  if (string.length === 9) {
    return `${string}@test.com`;
  }

  const newString = string + chars[Math.floor(Math.random() * chars.length)];

  return generateRandomEmail(newString);
};

export const generateYears = (years = [""], year = minimumYear): string[] => {
  if (year === maxYear) {
    return years;
  }

  const newArray = years;

  // eslint-disable-next-line fp/no-mutation
  newArray[newArray.length] = year.toString();

  return generateYears(newArray, year - 1);
};

export const buildAllowedCategory = (
  rule: DeepReadonly<SpendRuleFromQuery>,
): Maybe<Category> => {
  const allowedCategories: Category = {
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    line5: "",
    line6: "",
    line7: "",
    line8: "",
    line9: "",
    line10: "",
  };

  if (rule?.__typename === "MerchantCategorySpendRule") {
    return rule.merchantCategoryAllowed?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      allowedCategories,
    );
  }

  if (rule?.__typename === "MerchantCountrySpendRule") {
    return rule.merchantCountryAllowed?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      allowedCategories,
    );
  }

  if (rule?.__typename === "CVVSpendRule") {
    return rule.CVVAllowed?.reduce((accumulator, currentValue, index) => {
      const key = `line${index + 1}` as keyof Category;

      // eslint-disable-next-line fp/no-mutation
      accumulator[key] = currentValue;

      return accumulator;
    }, allowedCategories);
  }

  if (rule?.__typename === "StreetAddressSpendRule") {
    return rule.streetAddressAllowed?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      allowedCategories,
    );
  }

  if (rule?.__typename === "MerchantIdentifierSpendRule") {
    return rule.merchantIdentifierAllowed?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      allowedCategories,
    );
  }

  return allowedCategories;
};

export const buildBlockedCategory = (
  rule: DeepReadonly<SpendRuleFromQuery>,
): Maybe<Category> => {
  const blockedCategories: Category = {
    line1: "",
    line2: "",
    line3: "",
    line4: "",
    line5: "",
    line6: "",
    line7: "",
    line8: "",
    line9: "",
    line10: "",
  };

  if (rule?.__typename === "MerchantCategorySpendRule") {
    return rule.merchantCategoryBlocked?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      blockedCategories,
    );
  }

  if (rule?.__typename === "MerchantCountrySpendRule") {
    return rule.merchantCountryBlocked?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      blockedCategories,
    );
  }

  if (rule?.__typename === "CVVSpendRule") {
    return rule.CVVBlocked?.reduce((accumulator, currentValue, index) => {
      const key = `line${index + 1}` as keyof Category;

      // eslint-disable-next-line fp/no-mutation
      accumulator[key] = currentValue;

      return accumulator;
    }, blockedCategories);
  }

  if (rule?.__typename === "StreetAddressSpendRule") {
    return rule.streetAddressBlocked?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      blockedCategories,
    );
  }

  if (rule?.__typename === "MerchantIdentifierSpendRule") {
    return rule.merchantIdentifierBlocked?.reduce(
      (accumulator, currentValue, index) => {
        const key = `line${index + 1}` as keyof Category;

        // eslint-disable-next-line fp/no-mutation
        accumulator[key] = currentValue;

        return accumulator;
      },
      blockedCategories,
    );
  }

  return blockedCategories;
};

export const buildSpendRulesStatus = (
  spendRules:
    | Maybe<
        SpendRule & {
          __typename?: string | undefined;
        }
      >[]
    | undefined,
  attachedSpendRules: Maybe<SpendRuleConnection>,
): SpendRulesStatus =>
  spendRules?.map((rule) => ({
    isAttached: Boolean(
      attachedSpendRules?.edges?.find(
        (attachedRule) => attachedRule.node?.id === rule?.id,
      ),
    ),

    version:
      attachedSpendRules?.edges?.find(
        (attachedRule) => attachedRule.node?.id === rule?.id,
      )?.node?.version ?? rule?.version,

    id: rule?.id,
  }));

// eslint-disable-next-line complexity
export const monthToNumber = (month: string): string => {
  switch (month) {
    case "Jan":
      return "01";
    case "Feb":
      return "02";
    case "Mar":
      return "03";
    case "Apr":
      return "04";
    case "May":
      return "05";
    case "June":
      return "06";
    case "July":
      return "07";
    case "Aug":
      return "08";
    case "Sept":
      return "09";
    case "Oct":
      return "10";
    case "Nov":
      return "11";
    case "Dec":
      return "12";

    default:
      return "";
  }
};

export const monthsAbbr = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const months = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const days = [
  "",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const expirationYears = ((): Record<string, number> => {
  const mapYear = (year: number): string => year.toString().slice(-2);
  const maxExpirationYear = currentYear + 5;

  const years: Record<string, number> = {};

  // eslint-disable-next-line fp/no-let
  let index = currentYear;

  // eslint-disable-next-line fp/no-loops
  while (index <= maxExpirationYear) {
    // eslint-disable-next-line fp/no-mutation
    years[mapYear(index)] = index;
    // eslint-disable-next-line fp/no-mutation
    index += 1;
  }

  return years;
})();

// eslint-disable-next-line @typescript-eslint/require-array-sort-compare
export const merchantCategories = Object.keys(MerchantCategory).sort();
export const merchantCountries = Object.keys(Iso3166Alpha3Country);
export const CvvResponseCodes = Object.keys(CvvResponseCode);
export const AvsResponseCodes = Object.keys(AvsResponseCode);

export const findRequestedAmountType = (
  requestedAmount: Readonly<
    CardProductTransactionEventsFilterInput["requestedAmount"]
  >,
): keyof PartialMoneyFilterInput | undefined => {
  if (requestedAmount === undefined) {
    return undefined;
  }

  return Object.keys(requestedAmount).find(
    (key) => key !== "currencyCode",
  ) as keyof PartialMoneyFilterInput;
};

export const findRequestedAmount = (
  requestedAmount: Readonly<
    CardProductTransactionEventsFilterInput["requestedAmount"]
  >,
): Maybe<string> => {
  if (requestedAmount === undefined) {
    return undefined;
  }

  const amount =
    requestedAmount[
      Object.keys(requestedAmount).find(
        (key) => key !== "currencyCode",
      ) as keyof PartialMoneyFilterInput
    ];

  return typeof amount === "number" ? formatAmount(amount) : undefined;
};

export type DateFilterKey =
  | "All Time"
  | "Last 3 Months"
  | "Last 4 Weeks"
  | "Last 7 Days"
  | "Last 12 Months"
  | "Month to Date"
  | "Previous Month"
  | "Quarter to Date"
  | "Today"
  | "Year to Date"
  | "Yesterday";

export const dateFilterRange: Record<DateFilterKey, TimestampFilterInputRange> =
  {
    "All Time": {
      start: undefined,
      end: undefined,
    },

    Today: {
      start: startOfToday().toISOString(),
      end: endOfToday().toISOString(),
    },

    Yesterday: {
      start: startOfYesterday().toISOString(),
      end: endOfYesterday().toISOString(),
    },

    "Last 7 Days": {
      start: subWeeks(startOfToday(), 1).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Last 4 Weeks": {
      start: subWeeks(startOfToday(), 4).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Last 3 Months": {
      start: subMonths(startOfToday(), 3).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Last 12 Months": {
      start: subMonths(startOfToday(), 12).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Previous Month": {
      start: startOfMonth(subMonths(startOfToday(), 1)).toISOString(),
      end: endOfDay(lastDayOfMonth(subMonths(startOfToday(), 1))).toISOString(),
    },

    "Month to Date": {
      start: startOfMonth(startOfToday()).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Quarter to Date": {
      start: startOfQuarter(startOfToday()).toISOString(),
      end: endOfToday().toISOString(),
    },

    "Year to Date": {
      start: startOfYear(startOfToday()).toISOString(),
      end: endOfToday().toISOString(),
    },
  };

export const isPaymentCardExpired = (
  expirationDate: Maybe<string>,
): boolean => {
  if (expirationDate === undefined) {
    return false;
  }

  return compareAsc(new Date(expirationDate), currentDate) === -1;
};

export function enabledFeatureMap(
  financialAccount: FinancialAccount | undefined,
): string[] {
  const enabledFeatures: string[] = [];
  if (financialAccount) {
    const { features } = financialAccount;
    features?.forEach(
      (feature: DeepReadonly<{ enabled?: boolean; __typename?: string }>) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (feature.enabled && feature.__typename) {
          const featureLabel = CardFeatureMap[feature.__typename];
          enabledFeatures.push(featureLabel);
        }
      },
    );
  }
  return enabledFeatures;
}

import type { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: Readonly<
  PropsWithChildren<{
    condition: Readonly<boolean>;
    wrapper: React.FC;
  }>
>): JSX.Element => {
  const Wrapper = wrapper;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return condition ? <Wrapper>{children}</Wrapper> : <>{children}</>;
};

import { useCallback, useState, useEffect } from "react";
import type { PropsWithChildren } from "react";
import { useRouter } from "next/router";

import { MobileNavPopover } from "../MobileNavPopover";

import { AccountMobileNav } from "./AccountNav";
import SidebarNav from "./SidebarNav";
import { NavContainer } from "./nav/NavContainer";
import { Hamburger } from "./nav/Hamburger";
import { NavBarLayout } from "./nav/NavBarLayout";

// This is consumed outside of this package
// eslint-disable-next-line import/no-unused-modules
export const DashboardNav = ({
  children,
}: Readonly<PropsWithChildren<unknown>>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const toggleIsOpen = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const { cardProductId: cardProductIds } = router.query;

  const [cardProductId] = Array(cardProductIds).flat();

  useEffect(() => {
    setIsOpen(false);
  }, [cardProductId]);

  return (
    <NavContainer>
      <div className="relative">
        <NavBarLayout />
        <Hamburger onClick={toggleIsOpen} />
      </div>
      <MobileNavPopover isOpen={isOpen} onClick={toggleIsOpen}>
        <AccountMobileNav>
          <SidebarNav isMobile>{children}</SidebarNav>
        </AccountMobileNav>
      </MobileNavPopover>
    </NavContainer>
  );
};

/* eslint-disable import/no-unused-modules */
import { GraphQLClient } from "graphql-request";

import { getSdk, IntegrationEnvironment } from "./generated/graphql";
import "isomorphic-fetch";

// This rule has no override options: https://github.com/cartant/eslint-plugin-etc/blob/main/docs/rules/no-enum.md#options
// eslint-disable-next-line etc/no-enum
export enum SDKTokenKind {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  JWT,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers
  API_KEY,
}

export default function bay1Client(
  token: string,
  environment: IntegrationEnvironment = IntegrationEnvironment.TEST,
  tokenKind: SDKTokenKind = SDKTokenKind.JWT,
): ReturnType<typeof getSdk> {
  const client = new GraphQLClient(
    `${
      environment === IntegrationEnvironment.TEST
        ? process.env.NEXT_PUBLIC_TEST_API_URL
        : process.env.NEXT_PUBLIC_API_URL
    }/graphql`,
  );

  client.setHeader(
    "authorization",
    tokenKind === SDKTokenKind.JWT
      ? `Bearer ${token}`
      : `Basic ${Buffer.from(token).toString("base64")}`,
  );

  return getSdk(client);
}

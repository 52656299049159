/* eslint-disable import/no-unused-modules */

import React from "react";
import type {
  CardProduct,
  IntegrationEnvironment,
  Maybe,
} from "@bay1/sdk/generated/graphql";
import useSWR from "swr";
import { ClientError } from "graphql-request";
import { CommonAppContext } from "@bay1/data/CommonAppContext";
import bay1Client from "@bay1/sdk";
import type { MutatorCallback } from "swr/dist/types";
import { captureEvent, captureException, withScope } from "@sentry/node";

import {
  generateClientErrorMessage,
  isAccessDeniedError,
  AUTHENTICATION_INVALID_MESSAGE,
} from "../helpers";

type CardProductsResult = Maybe<CardProduct[]>;

const ERROR_MESSAGE = "Unable to load card products.";

const cardProductsFetcher = async (
  _key: string,
  jwt: string,
  environment: IntegrationEnvironment,
): Promise<CardProductsResult> => {
  try {
    const client = bay1Client(jwt, environment);
    const data = await client.ListCardProducts({
      first: 20,
    });

    if (!data.cardProducts) {
      throw new Error(ERROR_MESSAGE);
    }

    if (data.cardProducts.edges?.length ?? 0) {
      const results: CardProduct[] = [];

      data.cardProducts.edges?.forEach((edge) => {
        if (edge.node) {
          // eslint-disable-next-line fp/no-mutating-methods
          results.push(edge.node);
        }
      });

      return results;
    }

    return [];
  } catch (error: unknown) {
    withScope((scope) => {
      scope.setTransactionName("ListCardProducts");

      if (error instanceof ClientError) {
        if (isAccessDeniedError(error)) {
          throw new Error(AUTHENTICATION_INVALID_MESSAGE);
        } else {
          captureEvent({
            message: generateClientErrorMessage(ERROR_MESSAGE),

            tags: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              requestId: error.response.extensions.requestId as string,
            },

            extra: {
              errors: error.response.errors,
            },
          });
        }
      } else {
        captureException(error);
      }

      throw new Error(
        `${ERROR_MESSAGE} Please try again. If the problem persists, contact support@highnoteplatform.com.`,
      );
    });
  }

  return undefined;
};

export interface UseCardProductsResponse {
  cardProducts: CardProductsResult;
  error?: Error;
  isLoading: boolean;
  mutate?: (
    data?:
      | CardProductsResult
      | MutatorCallback<CardProductsResult>
      | Promise<CardProductsResult>,
    shouldRevalidate?: boolean,
  ) => Promise<CardProductsResult>;
}

export const useCardProducts = (): UseCardProductsResponse => {
  const { activeOrganization: organization } =
    React.useContext(CommonAppContext);

  const {
    data: cardProducts,
    error,
    mutate,
  } = useSWR<CardProductsResult, Error>(
    [
      `/organizations/${organization?.id}/cardProducts`,
      organization?.jwt ?? "",
      organization?.profile.environment,
    ],
    cardProductsFetcher,
    { revalidateOnFocus: false },
  );

  return {
    cardProducts,
    error,
    isLoading: !cardProducts && error === undefined,
    mutate,
  };
};

import React, { useMemo } from "react";
import type { SessionUser } from "@bay1/data/CommonAppContext";

import { getInitials } from "../urlHelper";

const Avatar = ({
  user,
  size = "small",
}: Readonly<{
  user: Readonly<SessionUser>;
  size?: "large" | "small";
}>): JSX.Element => {
  const initials = useMemo(() => getInitials(user.name ?? ""), [user.name]);

  if (typeof user.image === "string") {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        className={`${size === "small" ? "h-6 w-6" : "h-12 w-12"} rounded-full`}
        src={user.image}
      />
    );
  }

  return (
    <span
      className={`inline-flex items-center justify-center ${
        size === "small" ? "h-8 w-8" : "h-12 w-12"
      } rounded-full bg-black`}
    >
      <span className="text-xs text-bone">{initials}</span>
    </span>
  );
};

export default Avatar;

/* eslint-disable fp/no-mutation */

import React from "react";
import GlobalStyle from "@bay1/ui";
import "@bay1/ui/styles.css";
import "../styles.css";
import "@bay1/ui/HelveticaNow.css";
import "@bay1/ui/spaceMono.css";
import type { AppProps } from "next/app";
import { init } from "@sentry/node";
import { DASHBOARD_URL } from "@bay1/ui/urlHelper";
import { useRouter } from "next/router";
import { CommonAppContextProvider } from "@bay1/data";
import Head from "next/head";
import type { DeepReadonly } from "ts-essentials";

import SignedIn from "../components/SignedIn";
import { LoginGate } from "../components/LoginGate";

process.env.NEXTAUTH_URL = DASHBOARD_URL;

if (process.env.NEXT_PUBLIC_SENTRY_DSN !== undefined) {
  init({
    enabled: process.env.NODE_ENV === "production",

    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  });
}

const PUBLIC_ROUTES = [
  "/auth/signin",
  "/comingSoon",
  "/invitations/[invitationCode]",
];

const Dashboard = ({
  Component,
  pageProps,
  err,
}: DeepReadonly<AppProps & { err: unknown }>): JSX.Element => {
  const router = useRouter();

  return (
    <>
      <Head>
        <link href="/favicon.svg" rel="shortcut icon" />
      </Head>
      <GlobalStyle />
      {PUBLIC_ROUTES.includes(router.pathname) ? (
        <Component {...pageProps} err={err} />
      ) : (
        <CommonAppContextProvider router={router}>
          <LoginGate>
            <SignedIn>
              <Component {...pageProps} err={err} />
            </SignedIn>
          </LoginGate>
        </CommonAppContextProvider>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default Dashboard;

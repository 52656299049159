/* eslint-disable max-statements */

import { useRouter } from "next/router";
import type { SyntheticEvent } from "react";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useClickAway } from "react-use";
import { MARKETING_URL } from "@bay1/ui/urlHelper";
import Link from "next/link";

import {
  formatPhoneNumberSearch,
  formatSearchStringFromQuery,
} from "../formatters";

const SearchPill = ({
  title,
  description,
  handleAddFilter,
}: Readonly<{
  title: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  handleAddFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
}>): JSX.Element => (
  <button
    className="flex cursor-pointer space-x-2 text-xxs sm:text-xs items-center p-1 hover:bg-ash focus:outline-none focus:bg-ash w-full rounded-rounded"
    data-testid={`searchTag::${title}`}
    id={title}
    onClick={handleAddFilter}
    type="button"
  >
    <div className="bg-bone flex items-center w-max rounded-button p-1 px-2 text-xxs">
      {title}:
    </div>

    <span className="truncate">{description}</span>
  </button>
);

const searchTags: Record<string, string> = {
  name: "Person Account Holder Full Name",
  nameBusiness: "Business Account Holder Name",
  nameAuthorizedPerson: "Primary Authorized Person Full Name",
  email: "Person Account Holder Email",
  phone: "Person Account Holder Phone Number",
  idBusinessAccountHolder: "Business Account Holder Id",
};

export const SearchBar = (): JSX.Element => {
  const router = useRouter();
  const { id: organizationIds } = router.query;
  const [organizationId] = Array(organizationIds).flat();

  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState<Error>();

  const [searchString, setSearchString] = useState<string>(
    formatSearchStringFromQuery(router.query),
  );

  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    function handleKeyDown(event: { metaKey: boolean; key: string }) {
      if (event.metaKey && event.key === "k") {
        setShowDropdown(true);
        inputReference.current?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleShowDropdown = useCallback(() => {
    if (!showDropdown) {
      setShowDropdown(true);
    }
  }, [showDropdown]);

  const handleCloseDropdown = useCallback(() => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  }, [showDropdown]);

  const handleSearchStringChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setError(undefined);

      if (event.currentTarget.value.includes("phone:")) {
        setSearchString(
          `${event.currentTarget.value.slice(0, 6)} ${formatPhoneNumberSearch(
            event.currentTarget.value,
          )}`.trim(),
        );
      } else {
        setSearchString(event.currentTarget.value);
      }
    },
    [],
  );

  const handleSetCurrentFilterField = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setError(undefined);
      inputReference.current?.focus();
      setSearchString(`${event.currentTarget.id}: `);
      setShowDropdown(false);
    },
    [],
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (searchString.includes("email:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,
          query: { email: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("name:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,
          query: { name: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("phone:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/account-holders`,

          query: {
            phone: [
              searchString.split(":")[1].replaceAll("-", "").trim(),
              searchString.split(":")[1].trim(),
            ],
          },
        });
      } else if (searchString.includes("nameBusiness:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/business-account-holders`,
          query: { legalBusinessName: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("nameAuthorizedPerson:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/business-account-holders`,
          query: { authorizedPersonName: searchString.split(":")[1].trim() },
        });
      } else if (searchString.includes("idBusinessAccountHolder:")) {
        setShowDropdown(false);
        void router.push({
          pathname: `/organizations/${organizationId}/business-account-holders`,
          query: { idBusinessAccountHolder: searchString.split(":")[1].trim() },
        });
      } else {
        setShowDropdown(true);
        setError(new Error("Invalid search. Must use a tag."));
      }
      inputReference.current?.blur();
    },
    [organizationId, router, searchString],
  );

  useEffect(() => {
    setSearchString(formatSearchStringFromQuery(router.query));
  }, [router.query]);

  useClickAway(inputReference, (event) => {
    const path = event.composedPath() as Element[];
    if (
      !path.some(
        (element) => element.id === "search" || element.id === "dropdown",
      )
    ) {
      setShowDropdown(false);
    }
  });

  return (
    <div className="group relative w-full">
      <form onSubmit={handleSubmit}>
        <label className="relative block w-full sm:w-2/3">
          <span className="sr-only">Search</span>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <img alt="" src="/img/search-icon.svg" />
          </div>
          <input
            autoComplete="off"
            className="block bg-white w-full rounded-full h-10 pl-10 pr-2 text-xs focus:outline-none border-transparent hover:border-black focus:border-black focus:ring-black focus:ring-1 truncate"
            data-testid="input::search"
            id="search"
            maxLength={100}
            name="search"
            onChange={handleSearchStringChange}
            onClick={handleShowDropdown}
            onFocus={handleShowDropdown}
            placeholder="Search"
            ref={inputReference}
            type="text"
            value={searchString}
          />
        </label>

        {showDropdown && (
          <div
            className="bg-white absolute w-full sm:w-2/3 mt-2 shadow-lg rounded-rounded ring-1 ring-ash"
            id="dropdown"
          >
            <div className="p-2 space-y-0.5">
              {error === undefined ? (
                <div className="pl-1 pt-1 pb-1.5 text-xxs text-gray-500">
                  Select a tag and search
                </div>
              ) : (
                <div
                  className="text-xs text-red pl-1"
                  data-testid="search::error"
                >
                  {error.message}
                </div>
              )}

              {Object.entries(searchTags).map(([title, description]) => {
                if (
                  title.concat(":").startsWith(searchString) ||
                  searchString.startsWith(title)
                ) {
                  return (
                    <SearchPill
                      description={description}
                      handleAddFilter={handleSetCurrentFilterField}
                      key={title}
                      title={title}
                    />
                  );
                }
                return undefined;
              })}
            </div>

            <Link
              href={`${MARKETING_URL}/docs/platform/search-and-filters/dashboard-search`}
              passHref
            >
              <a
                className="flex px-3 py-2 bg-bone rounded-b-rounded text-xxs w-full hover:bg-ash focus:outline-none focus:bg-ash"
                data-testid="search::documentation-link"
                onBlur={handleCloseDropdown}
                rel="noreferrer"
                target="_blank"
              >
                Learn search tips
              </a>
            </Link>
          </div>
        )}
      </form>
    </div>
  );
};

/* eslint-disable import/exports-last */
import { Transition } from "@headlessui/react";
import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
} from "react";
import React, { useCallback, useEffect, useContext } from "react";
import Link from "next/link";
import { CommonAppContext } from "@bay1/data";
import type { Maybe } from "@bay1/sdk/generated/graphql";
import classNames from "classnames";

export const preventAccidentalSlideOverDismissal = (
  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
  event: React.KeyboardEvent<HTMLInputElement>,
): void => {
  if (event.key === "Escape") {
    event.stopPropagation();
  }
};

export interface SlideOverHeaderProps {
  title: string;
  titleTestId?: string;
  subtitle?: string;
  link?: {
    href: string;
    text: string;
  };
}

const SlideOverHeader = ({
  title,
  titleTestId,
  subtitle,
  link,
  children,
}: Readonly<PropsWithChildren<SlideOverHeaderProps>>): JSX.Element => {
  const { activeOrganization } = useContext(CommonAppContext);

  const hasAnchorTag = link?.href.includes("#") ?? false;
  return (
    <div className="pb-5" data-testid={(titleTestId ?? "") && titleTestId}>
      <div className="px-4 sm:px-8 pb-8 relative border-b border-ash">
        <h2
          className="headline font-display text-xl pb-2 pr-6"
          id="slide-over-heading"
        >
          {title}
        </h2>
        <p className="text-xs pr-6">{subtitle}</p>
        {link && (
          <Link
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            href={{
              pathname: hasAnchorTag ? link.href.split("#")[0] : link.href,

              hash: hasAnchorTag ? link.href.split("#")[1] : undefined,

              query: {
                activeOrganizationId: activeOrganization?.id,

                anchorTag: hasAnchorTag ? link.href.split("#")[1] : undefined,
              },
            }}
            passHref
          >
            <a
              className="inline-flex group items-center pt-3 sm:text-xs hover:text-gray-500 underline"
              data-testid="slide-over::header::documentation-link"
              rel="noreferrer"
              target="_blank"
            >
              <span className="text-xs">{link.text}</span>
            </a>
          </Link>
        )}
      </div>
      {children}
    </div>
  );
};

const SlideOver = ({
  isOpen,
  setIsOpen,
  header,
  children,
  afterLeave,
  isWide,
}: Readonly<
  PropsWithChildren<{
    isOpen: boolean;
    header?: SlideOverHeaderProps;
    setIsOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);

    // Some info!
    afterLeave?: () => void;
    isWide?: Maybe<boolean>;
  }>
>): JSX.Element => {
  // eslint-disable-next-line max-len, no-warning-comments
  // TODO: This won't work well with child components who need to "lock UI" for things like requests in flight

  // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types, react-hooks/exhaustive-deps
  const handleKeyUp = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const closeSlideOver = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (clickEvent: SyntheticEvent<HTMLButtonElement | HTMLDivElement>) => {
      if ("preventDefault" in clickEvent) {
        clickEvent.preventDefault();
      }

      setIsOpen(false);
    },
    [setIsOpen],
  );

  useEffect(() => {
    document.body.addEventListener("keyup", handleKeyUp);

    return (): void => {
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  const handleAfterLeave = useCallback((): void => {
    afterLeave?.();
  }, [afterLeave]);

  return (
    <Transition
      afterLeave={handleAfterLeave}
      className="z-50 absolute"
      show={isOpen}
    >
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            className="fixed inset-0"
            enter="ease-in-out duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-150 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              aria-hidden="true"
              className="absolute inset-0 bg-ash bg-opacity-80"
              onClick={closeSlideOver}
            />
          </Transition.Child>
          <section
            aria-labelledby="slide-over-heading"
            className="absolute inset-y-0 right-0 max-w-full flex"
          >
            <Transition.Child
              enter="transform transition ease-in-out duration-75 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-75 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classNames("h-full relative w-screen p-4", {
                  "max-w-2xl": isWide,
                  "max-w-lg": !(isWide ?? false),
                })}
              >
                <div className="h-full flex relative flex-col py-6 rounded-highnote bg-white overflow-y-scroll">
                  <div className="absolute z-10 top-0 right-0 pt-6 pr-2 flex sm:-ml-4 sm:pr-4">
                    <button
                      className="rounded-full flex items-center justify-center h-10 w-10 hover:bg-bone focus:bg-ash"
                      data-testid="slide-over::close-panel"
                      onClick={closeSlideOver}
                      type="button"
                    >
                      <span className="sr-only">Close panel</span>
                      {/* Heroicon name: outline/x */}
                      <svg
                        aria-hidden="true"
                        className="h-6 w-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 18L18 6M6 6l12 12"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                        />
                      </svg>
                    </button>
                  </div>
                  {header ? (
                    <SlideOverHeader {...header}>{children}</SlideOverHeader>
                  ) : (
                    { children }
                  )}
                </div>
              </div>
            </Transition.Child>
          </section>
        </div>
      </div>
    </Transition>
  );
};

export default SlideOver;

import React from "react";
import Link from "next/link";
import type { CardProduct } from "@bay1/sdk/generated/graphql";
import classNames from "classnames";
import { useRouter } from "next/router";

import { formatCardUsage } from "./formatters";

export const SidebarCardProduct = ({
  cardProduct,
}: Readonly<{
  cardProduct: Readonly<CardProduct>;
}>): JSX.Element => {
  const router = useRouter();
  const { cardProductId: cardProductIds, id: organizationIds } = router.query;
  const [cardProductId] = Array(cardProductIds).flat();
  const [organizationId] = Array(organizationIds).flat();

  return (
    <Link
      href={`/organizations/${organizationId}/card-products/${cardProduct.id}/transaction-events`}
    >
      <div
        className={classNames(
          "group flex relative items-center px-2 py-1 pr-6 text-md rounded-rounded hover:bg-ash cursor-pointer",
          {
            "bg-ash": cardProductId === cardProduct.id,
          },
        )}
        data-testid="sidebar::cardProduct"
      >
        <div
          className={classNames("mr-4 shrink-0 opacity-100", {
            "opacity-0": cardProductId === cardProduct.id,
          })}
        >
          <img alt="" src="/img/card-icon.svg" />
        </div>
        <div
          className={classNames("mr-4 shrink-0 absolute opacity-0", {
            "opacity-100": cardProductId === cardProduct.id,
          })}
        >
          <img alt="" src="/img/card-icon-active.svg" />
        </div>
        <div className="truncate cursor-pointer flex-1">
          {cardProduct.name}
          <span className="block text-xxs text-gray-500">
            {formatCardUsage(cardProduct.usage)}
          </span>
        </div>
      </div>
    </Link>
  );
};

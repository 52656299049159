import classNames from "classnames";
import React from "react";

export const LoadingSpinner = ({
  color = "#000",
  secondary = false,
}: Readonly<{
  color?: string;
  secondary?: boolean;
}>): JSX.Element => (
  <svg
    className={classNames("animate-spin stroke-current", { "ml-2": secondary })}
    height={secondary ? 17 : 20}
    viewBox="0 0 38 38"
    width={secondary ? 17 : 20}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="a" x1="8.042%" x2="65.682%" y1="0%" y2="23.865%">
        <stop offset="0%" stopColor={color} stopOpacity={0} />
        <stop offset="63.146%" stopColor={color} stopOpacity=".631" />
        <stop offset="100%" stopColor={color} />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          id="Oval-2"
          stroke="url(#a)"
          strokeWidth={2}
        />
        <circle cx={36} cy={18} fill={color} r={1} />
      </g>
    </g>
  </svg>
);

export const PageLoader = (): JSX.Element => (
  <div className="grid h-screen w-screen bg-bone">
    <div className="mx-auto self-center">
      <LoadingSpinner />
    </div>
  </div>
);

/* eslint-disable max-statements */
import type { ChangeEvent, SyntheticEvent } from "react";
import React, { useCallback, useState } from "react";
import type { CardProductInput, Maybe } from "@bay1/sdk/generated/graphql";
import { CardProductVertical, CardUsage } from "@bay1/sdk/generated/graphql";
import classNames from "classnames";
import { LoadingSpinner, ArrowButton } from "@bay1/ui";
import { useRouter } from "next/router";

import { ErrorBox } from "../ErrorBox";
import { useCreateCardProduct } from "../../hooks/useCreateCardProduct";
import { useCardProducts } from "../../hooks/useCardProducts";
import { preventAccidentalSlideOverDismissal } from "../SlideOver";
import { DEFAULT_ERROR_MESSAGE } from "../../helpers";

export const CreateCardProduct = (): JSX.Element => {
  const router = useRouter();
  const [requestInFlight, setRequestInFlight] = useState(false);
  const [stateError, setStateError] = useState<Maybe<Error>>();
  const [newCardProduct, setNewCardProduct] = useState<CardProductInput>({
    name: "",
    usage: CardUsage.SINGLE_USE,
    vertical: CardProductVertical.EARNED_WAGE_ACCESS,
  });

  const { cardProducts, error: cardProductsError, mutate } = useCardProducts();
  const { createCardProduct } = useCreateCardProduct();

  const { id: organizationIds } = router.query;
  const [organizationId] = Array(organizationIds).flat();

  const error = cardProductsError ?? stateError;

  const handleSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (newCardProduct.name.length === 0) {
        setStateError(new Error("Card Product Name cannot be blank."));

        return;
      }

      setStateError(undefined);
      setRequestInFlight(true);

      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const cardProduct = await createCardProduct!(newCardProduct);

        if (mutate && cardProduct) {
          void mutate([cardProduct, ...(cardProducts ?? [])]);
        }

        void router.push(
          `/organizations/${organizationId}/card-products/${cardProduct?.id}/settings`,
        );
      } catch {
        setRequestInFlight(false);
        setStateError(new Error(DEFAULT_ERROR_MESSAGE));

        if (mutate) {
          void mutate(cardProducts);
        }
      }
    },
    [
      cardProducts,
      createCardProduct,
      mutate,
      newCardProduct,
      organizationId,
      router,
    ],
  );

  const handleCardProductNameChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      setStateError(undefined);

      setNewCardProduct({
        ...newCardProduct,
        name: event.currentTarget.value,
      });
    },
    [newCardProduct],
  );

  return (
    <form
      className="slideOverContent"
      data-testid="form::createCardProduct"
      onSubmit={handleSubmit}
    >
      {error && (
        <div className="pb-6">
          <ErrorBox message={error.message} />
        </div>
      )}
      <label
        className="formLabel cursor-pointer"
        data-testid="form::createCardProduct::label::cardProductName"
        htmlFor="cardProductName"
      >
        Card Product Name
      </label>
      <input
        className="baseInput"
        data-testid="form::createCardProduct::input::cardProductName"
        disabled={requestInFlight}
        id="cardProductName"
        maxLength={255}
        name="cardProductName"
        onChange={handleCardProductNameChange}
        onKeyUp={preventAccidentalSlideOverDismissal}
        placeholder="Product Name"
        type="text"
        value={newCardProduct.name}
      />
      {/* Card Usage */}
      <fieldset className="mt-5">
        <legend className="font-medium">Card Usage</legend>
        <div className="mt-2 space-y-5">
          <div className="relative flex items-start">
            <label className="cursor-pointer" htmlFor="usage_single-use">
              <div className="absolute flex items-center h-5">
                <input
                  aria-describedby="usage_single-use_description"
                  checked={newCardProduct.usage === CardUsage.SINGLE_USE}
                  className="focus:ring-black h-4 w-4 text-green border-gray"
                  disabled={requestInFlight}
                  id="usage_single-use"
                  name="usage_single-use"
                  // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                  onChange={(): void => {
                    setNewCardProduct({
                      ...newCardProduct,
                      usage: CardUsage.SINGLE_USE,
                    });
                  }}
                  type="radio"
                  value={CardUsage.SINGLE_USE}
                />
              </div>
              <div className="pl-7 text-sm">
                <span className="font-medium">Single-use</span>
                <p id="usage_single-use_description">
                  Cards can only be used once.
                </p>
              </div>
            </label>
          </div>
          <div className="relative flex items-start">
            <label className="cursor-pointer" htmlFor="usage_multi-use">
              <div className="absolute flex items-center h-5">
                <input
                  aria-describedby="privacy_private-to-project_description"
                  checked={newCardProduct.usage === CardUsage.MULTI_USE}
                  className="focus:ring-black h-4 w-4 text-green border-gray"
                  disabled={requestInFlight}
                  id="usage_multi-use"
                  name="usage_multi-use"
                  // eslint-disable-next-line react/jsx-no-bind, react-perf/jsx-no-new-function-as-prop
                  onChange={(): void => {
                    setNewCardProduct({
                      ...newCardProduct,
                      usage: CardUsage.MULTI_USE,
                    });
                  }}
                  type="radio"
                  value={CardUsage.MULTI_USE}
                />
              </div>
              <div className="pl-7 text-sm">
                <span className="font-medium">Multi-use</span>
                <p id="usage_multi-use_description">
                  Cards can be used an unlimited number of times.
                </p>
              </div>
            </label>
          </div>
        </div>
      </fieldset>
      <button
        className={classNames("btn", { btnInFlight: requestInFlight })}
        data-testid="form::createCardProduct::submit"
        disabled={requestInFlight}
        type="submit"
      >
        {" "}
        Create Card Product
        <div className="mt-1 right-5 absolute">
          {requestInFlight ? <LoadingSpinner /> : <ArrowButton isPrimary />}
        </div>
      </button>
    </form>
  );
};
